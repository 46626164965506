import JSZip from "jszip";
import React, { useState } from "react";
const encodeing = "utf-8";

function UpdateQuestionnaireTests(sendQuestionnaireTestsFunction) {
  const [content, setContent] = useState(null);

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = event => {
      const zip = new JSZip();
      zip.loadAsync(event.target.result).then(zipContent => {
        setContent(zipContent);
      });
    };
    reader.readAsArrayBuffer(selectedFile);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (content) {
      sendQuestionnaireTestsFunction(content);
    } else {
      console.log("No file selected");
    }
  };

  return (
    <form>
      <label>
        Upload Questionnaire Test:
        <input
          key="default"
          type="file"
          onClick={e => (e.target.value = null)}
          onChange={handleFileChange}
          accept=".zip"
        />
      </label>
      <button type="button" onClick={handleSubmit}>
        Update Questoinnaire
      </button>
    </form>
  );
}

export default UpdateQuestionnaireTests;
