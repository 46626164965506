import React, { useState } from 'react';

import { postAlarmtbrakeRequest } from "../../../../Questionnaire/QuestionneerAPI/QuestionneerPost";

const AlarmBrake = () => {
  const [showText, setShowText] = useState(false);

  const handleMouseEnter = () => {
    setShowText(true);
  };

  const handleMouseLeave = () => {
    setShowText(false);
  };
  const handleAlarmClick = () => {
    postAlarmtbrakeRequest();
  };

  return (
    <>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <button
          onClick={handleAlarmClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="alarm-button"
          style={{ fontSize: '2em', backgroundColor: '#333' }}
        >
          🚨
        </button>
        {showText && (
          <div
            style={{
              position: 'absolute',
              top: '30px', // Adjust this value to move the text box relative to the button
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '3px',
              backgroundColor: '#fff',
              border: '1px solid #333',
              borderRadius: '4px',
              color: '#000',
              fontSize: '0.8em',
            }}
          >
            אזעקה
          </div>
        )}
      </div>
    </>
  );
};

export { AlarmBrake };
