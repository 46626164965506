// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    background-color: #f1f1f1;
    color: #333;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* padding: 8px; */
  }
  
  .dropdown-menu button {
    display: block;
    background-color: transparent;
    border: none;
    color: #333;
    padding: 4px 8px;
    margin-bottom: 4px;
    cursor: pointer;
  }
  
  .dropdown-menu button:hover {
    background-color: #f1f1f1;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Styles/DropdownMenu.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,6BAA6B;IAC7B,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".dropdown {\n    position: relative;\n    display: inline-block;\n  }\n  \n  .dropdown-toggle {\n    background-color: #f1f1f1;\n    color: #333;\n    padding: 8px 16px;\n    border: none;\n    cursor: pointer;\n  }\n  \n  .dropdown-menu {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n    /* padding: 8px; */\n  }\n  \n  .dropdown-menu button {\n    display: block;\n    background-color: transparent;\n    border: none;\n    color: #333;\n    padding: 4px 8px;\n    margin-bottom: 4px;\n    cursor: pointer;\n  }\n  \n  .dropdown-menu button:hover {\n    background-color: #f1f1f1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
