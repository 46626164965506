import React, { useEffect, useRef, useState } from "react";
import "../../../../../Styles/DropdownMenu.css";

const DropdownMenu = (
  questionesAnswered = 0,
  firstQuestionIndex = 0,
  jumpToQuestions = null
) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = value => {
    if (jumpToQuestions) {
      jumpToQuestions(firstQuestionIndex + value - 1);
    }
    setIsOpen(false);
  };

  if (!questionesAnswered || questionesAnswered <= 0) {
    return null;
  }
  // Generate an array of numbers based on questionesAnswered
  const buttonCount = Array.from(Array(questionesAnswered).keys());

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button
        className="dropdown-toggle"
        onClick={toggleDropdown}
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        עיין בשאלות
      </button>
      <div className="dropdown-menu">
        {isOpen &&
          buttonCount.map(index => (
            <button
              key={index}
              onClick={() => handleButtonClick(index + 1)}
              style={{
                backgroundColor: "#fff",
                color: "#000"
              }}
            >
              שאלה {index + 1}
            </button>
          ))}
      </div>
    </div>
  );
};

export default DropdownMenu;
