// ErrorBoundary.js

import React, { Component } from "react";
import history from "../services/history";
import userLogger from "../services/userLogger";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    userLogger.error("Error caught by ErrorBoundary: " + error + errorInfo, false, true);
    // You can perform additional actions here, e.g., log the error

    // Do not perform a redirect here, as it may cause issues

    // You might want to set a flag to trigger a redirect in componentDidUpdate
    this.setState({ redirectToHome: true });
    setTimeout(() => {
      history.push("/");
      window.location.reload();
    }, 500);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.redirectToHome && !prevState.redirectToHome) {
      // Redirect to the base URL or any other route as needed
      userLogger.error("Error caught by ErrorBoundary: ", false, true);
      setTimeout(() => {
        history.push("/");
        window.location.reload();
      }, 500);
    }
  }
  componentDidCatch(error, errorInfo) {
    userLogger.error("Error caught by ErrorBoundary: " + error + errorInfo, false, true);
    // Log the error, but do not perform a redirect here
    // You might want to set a flag to trigger a redirect in componentDidUpdate
    setTimeout(() => {
      history.push("/");
      window.location.reload();
    }, 500);
  }
  render() {
    if (this.state.hasError) {
      userLogger.error("Error caught by ErrorBoundary in render", false, true);
      setTimeout(() => {
        history.push("/");
        window.location.reload();
      }, 500);
      // Render a fallback UI
      return <h1>Something went wrong. Please try again.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
