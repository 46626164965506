import Papa from "papaparse";
import api from "../../../../services/api";

const downloadUsersResults = () => {
  api
    .get("/admin/users-results", { responseType: "blob" })
    .then(response => {
      if (response.status == 201 || response.status == 204) {
        alert("קובץ התשובות ריק");
        return;
      }
      const csvData = response.data;

      // Parse the CSV string into an array of objects
      const reader = new FileReader();
      reader.onload = function () {
        Papa.parse(reader.result, {
          header: true,
          complete: function (results) { },
          error: function (error) {
            console.log("error", error);
          },
          download: true
        });
      };
      reader.readAsText(csvData);

      const url = URL.createObjectURL(new Blob([csvData]));
      const link = document.createElement("a");
      link.href = url;
      link.download = "results.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.log("error", error);
    });
};

const downloadUsersAttended = () => {
  api
    .get("/admin/users-attended", { responseType: "blob" })
    .then(response => {
      if (response.status == 201 || response.status == 204) {
        alert("קובץ התשובות ריק");
        return;
      }
      const csvData = response.data;

      // Parse the CSV string into an array of objects
      const reader = new FileReader();
      reader.onload = function () {
        Papa.parse(reader.result, {
          header: true,
          complete: function (results) { },
          error: function (error) {
            console.log("error", error);
          },
          download: true
        });
      };
      reader.readAsText(csvData);

      const url = URL.createObjectURL(new Blob([csvData]));
      const link = document.createElement("a");
      link.href = url;
      link.download = "attended.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.log("error", error);
    });
};

const downloadQuestionnaireTests = () => {
  api
    .get("/admin/questionnaire-tests", { responseType: "blob" })
    .then(response => {
      // download a zip file sent from server
      const zipData = response.data;
      const url = URL.createObjectURL(new Blob([zipData]));
      const link = document.createElement("a");
      link.href = url; // set the href attribute to the URL of the object
      link.download = "tests.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.log("error", error);
    });
};

export { downloadQuestionnaireTests, downloadUsersResults, downloadUsersAttended };
