import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const OpenQuestion = ({
  id,
  question_text,
  onResponse,
  type = null,
  selectedResponse = null,
}) => {
  const [response, setResponse] = useState("");
  const [first, setFirst] = useState(true);
  const prevIdRef = React.useRef();

  const handleResponseChange = event => {
    const newResponse = event.target.value;
    setResponse(newResponse);
    onResponse({ questionID: id, response: newResponse });
  };
  useEffect(() => {
    // Reset the response state when the id prop changes
    console.log("run first time");
    console.log("selectedResponse: ", selectedResponse);
    setResponse(selectedResponse ? selectedResponse : "");
    setFirst(false);
  }, [selectedResponse]);
  const handleFocus = event => {
    if (!response) {
      setResponse("");
    } else {
      setResponse(response);
    }
  };

  const question_html =
    '<h3 style={{ marginBottom: "1rem", textAlign: "right" }}><div dir="rtl">' +
    question_text.replace(/\n/g, "<br>").replace("\\n", "<br>") +
    "</div></h3>";

  const validateInput = input => {
    switch (type) {
      case "שאלה פתוחה של קודון":
        // 3 letters (A, M, N, or O)
        const regex = /^[AMNO]{3}$/;
        return regex.test(input.toUpperCase());
      default:
        // No validation needed for other types
        return true;
    }
  };

  const handleBlur = event => {
    const input = event.target.value;
    const isValid = validateInput(input);
    if (!isValid) {
      alert(`הפלט של: ${type} לא תקין!`);
      setResponse("");
      onResponse({ questionID: id, response: "" });
    }
  };
  // console.log("selectedResponse: ", selectedResponse);
  // console.log("the response now is: ", response);
  return (
    <div style={{ textAlign: "right" }}>
      <div dangerouslySetInnerHTML={{ __html: question_html }} />
      <TextField
        key={id} // This is the key to re-render the component
        id={`open-question-${id}`}
        label="התשובה שלך"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={response || ""}
        onChange={handleResponseChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ marginBottom: "0.5rem" }}
        dir="rtl"
      />
    </div>
  );
};

export default OpenQuestion;
