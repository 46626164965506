import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import {
  postAnswerRaisedHandQuestion,
  postCloseUserQueue
} from "../../Operator/OperatorAPI/OperatorPost";

const UserQuestionsDialog = ({ onClose, user }) => {
  // returns a dialog of all the questions of the user
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [answers, setAnswers] = useState({});

  const closeQuestionQueue = () => {
    setSelectedQueue(null);
    setAnswers({});
    onClose();
  };

  const handleQueueClick = queue => {
    setSelectedQueue(queue);
  };

  const handleAnswerChange = (questionId, event) => {
    setAnswers({
      ...answers,
      [`${selectedQueue.id}-${questionId}`]: event.target.value
    });
  };

  const closeQueue = () => {
    postCloseUserQueue(user.id, selectedQueue.id);
    setSelectedQueue(null);
  };

  const handleSubmit = questionID => {
    postAnswerRaisedHandQuestion(
      user.id,
      questionID,
      selectedQueue.id,
      answers[`${selectedQueue.id}-${questionID}`]
    );
    setSelectedQueue(null);
  };

  if (!user) return null;
  const userQuestionsQueues = user.raise_hand_questions || []; // add a default value of an empty array

  return (
    <Dialog
      open={true}
      onClose={closeQuestionQueue}
      maxWidth="lg" // set the maxWidth to "lg" for a wider dialog box
      fullWidth="100vh"
      sx={{ height: "90vh", maxHeight: "190vh" }}
    >
      <DialogTitle
        sx={{ textAlign: "right", direction: "rtl" }}
      >{`שאלות של: ${user?.name}`}</DialogTitle>
      <DialogContent sx={{ marginLeft: "auto" }}>
        {selectedQueue ? (
          <>
            {selectedQueue.questions.map(question => (
              <div key={question.id}>
                <Typography style={{ textAlign: "right", direction: "rtl" }}>
                  שאלה: {question.message}
                </Typography>
                {question.answers &&
                  question.answers.map(answer => (
                    <Typography
                      style={{ textAlign: "right", direction: "rtl" }}
                    >
                      תשובה: {answer}
                    </Typography>
                  ))}
                {selectedQueue.questions.indexOf(question) ===
                  selectedQueue.questions.length - 1 && (
                  <>
                    <div>
                      <div
                        style={{
                          textAlign: "left",
                          direction: "rtl",
                          width: "130vh"
                        }}
                      >
                        <TextField
                          value={
                            answers[`${selectedQueue.id}-${question.id}`] || ""
                          }
                          onChange={event => {
                            handleAnswerChange(question.id, event);
                          }}
                          margin="normal"
                          variant="outlined"
                          label="Answer"
                          fullWidth
                        />
                      </div>
                      <Button
                        onClick={() => {
                          handleSubmit(question.id);
                          onClose();
                        }}
                      >
                        תשובה
                      </Button>
                      <Button
                        onClick={() => {
                          closeQueue();
                          onClose();
                        }}
                      >
                        סגור את השרשור
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ))}
            <Button
              onClick={() => {
                setSelectedQueue(null);
              }}
            >
              Back
            </Button>
          </>
        ) : (
          <>
            {userQuestionsQueues.map(queue => (
              <div
                key={queue.id}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={() => handleQueueClick(queue)}
                  sx={{
                    textAlign: "right",
                    direction: "rtl",
                    border: "1px solid black",
                    borderRadius: "5px",
                    marginRight: "10px",
                    marginLeft: "auto"
                  }}
                  style={{
                    backgroundColor:
                      queue.isOpen && !queue.isAllAnswered
                        ? "yellow"
                        : "transparent"
                  }}
                >
                  <Typography>
                    {queue.questions[queue.questions.length - 1].message}
                  </Typography>
                </Button>
              </div>
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default UserQuestionsDialog;
