import React from "react";
import Clock from "../../../../Clock";
import "../../../../Styles/TopNav.css";
import DropdownMenu from "./Buttons/DropdownMenu";

import { AlarmBrake } from "./Buttons/AlarmButton";
import RaiseHand from "./Buttons/RaiseHand";
import { ToiletBrake } from "./Buttons/ToiletBrake";

const TopNav = (
  shouldAddAskToilet = false,
  shouldAddRaiseHand = false,
  questionAnswered = 0,
  firstQuestionIndex = 0,
  jumpToQuestions = null
) => {
  const dropDownMenu = DropdownMenu(
    questionAnswered,
    firstQuestionIndex,
    jumpToQuestions
  );
  return (
    <>
      <nav className="nav">
        <ul> {Clock()} :שעה</ul>
        <ul style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <li className="site-title" style={{ fontFamily: "Arial" }}>
            מבחן אחמנון
          </li>
        </ul>
        <ul style={{ marginRight: "50px" }}>{ }</ul>
        <ul>
          {dropDownMenu /* Dropdown menu for jumping to questions */}
          {shouldAddAskToilet && <ToiletBrake />}
          {shouldAddRaiseHand && <RaiseHand />}
          {(shouldAddAskToilet || shouldAddRaiseHand) && <AlarmBrake />}
        </ul>
      </nav>
      <br />
    </>
  );
};

export default TopNav;
