import api from "../../../../../services/api";
import userLogger from "../../../../../services/userLogger";

const requestPdfPagesAmount = (
  pdfPath,
  setFunction = null,
  varifieFunction = null
) => {
  const encodedResourcePath = encodeURIComponent(pdfPath);
  userLogger.log(`Requesting pdf pages from server`);
  api
    .get(`/questionnaire/pdf-pages-amout?pdf=${encodedResourcePath}`)
    .then(response => {
      if (setFunction) setFunction(response.data.pagesAmount);
      if (varifieFunction) varifieFunction(true);

      return response.data.pagesAmount;
    })
    .catch(error => {
      userLogger.error("error getting all refrences from server: ", error);
    });
};

const requestHTMLFile = (
  filePath,
  setFunction = null,
  varifieFunction = null
) => {
  const encodedResourcePath = encodeURIComponent(filePath);
  userLogger.log(`Requesting html file from server`);
  api
    .get(`/questionnaire/get-html-file?html=${encodedResourcePath}`)
    .then(response => {
      if (setFunction) setFunction(response.data);
      if (varifieFunction) varifieFunction(true);

      return response.data;
    })
    .catch(error => {
      userLogger.error("error getting html file from server: ", error);
    });
};

const requestVideoStream = (
  filePath,
  setFunction = null,
  varifieFunction = null
) => {
  const encodedResourcePath = encodeURIComponent(filePath);
  userLogger.log(`Requesting video stream from server`);
  api
    .get(`/questionnaire/video-stream?path=${encodedResourcePath}`)
    .then(response => {
      if (setFunction) setFunction(response.data);
      if (varifieFunction) varifieFunction(true);

      return response.data;
    });
};

export { requestHTMLFile, requestPdfPagesAmount, requestVideoStream };
