// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav {
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
  }
  
  .site-title {
    font-size: 2.5rem;
  }
  
  .nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
  }`, "",{"version":3,"sources":["webpack://./src/components/Styles/TopNav.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,oBAAoB;IACpB,SAAS;IACT,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,UAAU;IACV,SAAS;IACT,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,cAAc;IACd,qBAAqB;IACrB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;EACjB","sourcesContent":[".nav {\n    background-color: #333;\n    color: white;\n    display: flex;\n    justify-content: space-between;\n    align-items: stretch;\n    gap: 2rem;\n    padding: 0 1rem;\n  }\n  \n  .site-title {\n    font-size: 2.5rem;\n  }\n  \n  .nav ul {\n    padding: 0;\n    margin: 0;\n    list-style: none;\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n  }\n  \n  .nav a {\n    color: inherit;\n    text-decoration: none;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    padding: .25rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
