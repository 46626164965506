import React, { useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { useEffect } from "react";
import { requestIsSameTest } from "../../QuestionneerAPI/QuestionneerGet";

const ProtectedReference = ({ refrenceRender, resourcePath }) => {
  const [currectTest, setCurrectTest] = useState(false);
  const [recivedCurrectTest, setRecivedCurrectTest] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  let path = resourcePath;
  if (!resourcePath) {
    path = urlParams.get("path");
  }
  useEffect(() => {
    if (!recivedCurrectTest) {
      requestIsSameTest(path, setCurrectTest, setRecivedCurrectTest);
    }
  }, [recivedCurrectTest]);

  useEffect(() => {
    if (recivedCurrectTest && !currectTest) {
      setIsAllowed(false);
      window.close();
    }
  }, [currectTest]);

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        requestIsSameTest(path, setCurrectTest, setRecivedCurrectTest);
      } catch (error) {
        setIsAllowed(false);
        window.close();
      }
      if (recivedCurrectTest && !currectTest) {
        setIsAllowed(false);
        window.close();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [currectTest, recivedCurrectTest]);

  return <div>{isAllowed ? refrenceRender : <h1>חומר העזר נסגר</h1>}</div>;
};

const RestrictedResourceLink = ({
  refrenceType,
  resourcePath,
  name,
  otherOptions = null
}) => {
  const otherOptionsPath = otherOptions
    ? `&${new URLSearchParams(otherOptions).toString()}`
    : "";
  const encodedResourcePath = encodeURIComponent(resourcePath);
  let resourceUrl = null;
  if (refrenceType == "pdf") {
    resourceUrl = `/questionnaire/pdf-file?path=${encodedResourcePath}${otherOptionsPath}`;
  } else if (refrenceType == "html") {
    resourceUrl = `/questionnaire/html-file?path=${encodedResourcePath}${otherOptionsPath}`;
  }
  const handleClick = () => {
    window.open(resourceUrl, "_blank");
  };

  return (
    <a href="#" onClick={handleClick}>
      <span style={{ fontSize: "1.5em" }}>{name}</span>
    </a>
  );
};
export { ProtectedReference, RestrictedResourceLink };
