import { Button, ListItem } from "@mui/material";
import { styled } from "@mui/system";

// Define a styled component for the container that holds the questionnaire
const Container = styled("div")({
  maxWidth: 600,
  margin: "0 auto",
  padding: "16px",
  backgroundColor: "#f5f5f5",
  borderRadius: 8,
  textAlign: "right"
});

// Define a styled component for the container that holds the "Previous", "Next", and "Submit" buttons
const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "16px"
});

// Define a styled component for the "Previous", "Next", and "Submit" buttons
const StyledButton = styled(Button)({
  borderRadius: 999,
  padding: "12px 24px"
});

// Define a styled component for the list item that displays the user's response to a question
const StyledListItem = styled(ListItem)({
  paddingLeft: 0,

  "& .MuiListItemIcon-root": {
    minWidth: "auto",
    marginRight: "8px"
  },

  "& .MuiCheckbox-root": {
    padding: 0,
    borderRadius: 4
  }
});

export { ButtonContainer, Container, StyledButton, StyledListItem };
