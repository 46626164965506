import api from "../../../../services/api";
import history from "../../../../services/history";
import userLogger from "../../../../services/userLogger";

// Reusable function to handle unauthorized access
const handleUnauthorizedAccess = response => {
  if (
    response.status === 400 ||
    (response.data &&
      response.data.message &&
      response.data.message === "User authentication failed")
  ) {
    // Perform the redirect or other actions
    // Handle other errors as needed
    history.push("/");
    window.location.reload();
  }
  return response;
};

const requestRefrences = (setFunction = null, varifieFunction = null) => {
  userLogger.log(`Requesting all refrences from server`);
  api
    .get("/questionnaire/all-refrences")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      const returnedRefrences = response.data.refrences.valueOf();
      if (setFunction) setFunction(returnedRefrences);
      if (varifieFunction) varifieFunction(true);

      userLogger.log(
        `All refrences received from server and returned: `,
        returnedRefrences
      );
      return returnedRefrences;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("error getting all refrences from server: ", error);
    });
};

const requestQuestion = async (
  currentQuestion = "",
  setFunction = null,
  varifieFunction = null,
  shouldJumpPages = true
) => {
  let funcResponse = null;
  await api
    .get("/questionnaire/next-question?current_question=" + currentQuestion)
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (response.data.question) {
        userLogger.log("question from api get: ", response);
        if (setFunction) setFunction(response.data.question);
        if (varifieFunction) varifieFunction(true);

        response = response.data.question;
      } else if (response.data.message == "User has finished chapter") {
        if (shouldJumpPages) {
          funcResponse = "Chapter Ended";
          history.push("/questionnaire/end-chapter");
          window.location.reload();
        } else {
          funcResponse = "Chapter Ended";
        }
      } else if (response.data.message == "User has finished test") {
        if (shouldJumpPages) {
          funcResponse = "Test Ended";
          history.push("/questionnaire/end-test");
          window.location.reload();
        } else {
          funcResponse = "Test Ended";
        }
      } else if (response.data.message === "User has finished questionnaire") {
        if (shouldJumpPages) {
          funcResponse = "Questionnaire Ended";
          history.push("/questionnaire/end-questionneer");
          window.location.reload();
        } else {
          funcResponse = "Questionnaire Ended";
        }
      }
    })
    .catch(error => {
      console.log("error", error);
      history.push("/");
      window.location.reload();
      console.error("Error getting questionnaire question:", error);
    });
  return funcResponse;
};

// Define an effect to request the user's responses from the server
const requestResponses = async (setFunction = null, varifieFunction = null) => {
  userLogger.debug(`Requesting user responses from server`);
  api
    .get("/questionnaire/user-responses")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      const newResponses = response.data.responses;
      if (setFunction) setFunction(newResponses);
      if (varifieFunction) varifieFunction(true);
      userLogger.debug(`User responses received from server`);
      return newResponses;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting responses from server ", error);
    });
};

// Define an effect to request all questions from the server
const requestAllQuestions = (
  setFunction = null,
  varifieFunction = null,
  shouldJumpPages = false
) => {
  userLogger.log(`Requesting all questions from server`);
  api
    .get("/questionnaire/all-questions")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (response.data.message == "User has finished chapter") {
        // if there are questions in the chapter:
        if (response.data.questions && !response.data.only_have_refrences) {
          if (setFunction) setFunction(response.data.questions);
          if (varifieFunction) varifieFunction(true);
        } else if (shouldJumpPages) {
          history.push("/questionnaire/end-chapter");
          window.location.reload();
        } else {
          return "Chapter Ended";
        }
      } else if (response.data.message == "User has finished test") {
        // if there are questions in the chapter:
        if (response.data.questions && !response.data.only_have_refrences) {
          if (setFunction) setFunction(response.data.questions);
          if (varifieFunction) varifieFunction(true);
        } else if (shouldJumpPages) {
          history.push("/questionnaire/end-test");
          window.location.reload();
        } else {
          return "Test Ended";
        }
      } else if (response.data.message === "User has finished questionnaire") {
        if (response.data.questions && !response.data.only_have_refrences) {
          if (setFunction) setFunction(response.data.questions);
          if (varifieFunction) varifieFunction(true);
        } else if (shouldJumpPages) {
          history.push("/questionnaire/end-questionneer");
          window.location.reload();
        } else {
          return "Questionnaire Ended";
        }
      }

      if (response.data.questions) {
        userLogger.log("all questions from api get:", response.data.questions);
        if (setFunction) setFunction(response.data.questions);
        if (varifieFunction) varifieFunction(true);
        userLogger.log(
          `All questions received from server and returned: `,
          response.data.questions
        );
        return response.data.questions;
      }
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("error getting all questions from server: ", error);
    });
};

// Get start of chapter index from server and set it to function
const requestStartOfChapterIndex = (
  setFunction = null,
  varifieFunction = null
) => {
  api
    .get("/questionnaire/chapter-start-index")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (setFunction) setFunction(response.data.chapterStartIndex);
      if (varifieFunction) varifieFunction(true);

      userLogger.log("Start of chapter index received from server");
      return response.data.chapterStartIndex;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting start of chapter index", error);
    });
  return null;
};

// Request index from server
const requestQuestionIndex = async (
  setFunction = null,
  varifieFunction = null
) => {
  userLogger.debug(`Requesting question index from server`);
  api
    .get("/questionnaire/question-index")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      const newRequesetIndex = response.data.questionIndex;
      if (setFunction) setFunction(newRequesetIndex);
      if (varifieFunction) varifieFunction(true);

      userLogger.debug(
        `Question index received from server and returned: ${newRequesetIndex}`
      );
      return newRequesetIndex;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting index from server ", error);
    });
};

const requestChapterTimeout = (setFunction = null, varifieFunction = null) => {
  api
    .get("/questionnaire/chapter-timeout")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (setFunction) setFunction(response.data.timeout);
      if (varifieFunction) varifieFunction(true);
      return response.data.timeout;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting chapter timeout:", error);
    });
};

// Define a function to get chapter's refrences timeout
const requestRefrencesTimeout = (
  setFunction = null,
  varifieFunction = null
) => {
  api
    .get("/questionnaire/refrences-timeout")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (setFunction) setFunction(response.data.timeout);
      if (varifieFunction) varifieFunction(true);
      return response.data.timeout;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting refrences timeout:", error);
    });
};

const requestShouldEndTest = (setFunction = null, varifieFunction = null) => {
  api
    .get("/questionnaire/should-end-test")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (setFunction) setFunction(response.data.shouldEnd);
      if (varifieFunction) varifieFunction(true);
      return response.data.shouldEnd;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();

      console.error("Error getting test shouldEnd:", error);
    });
};

const requestQuestionnaireStatus = async (
  setFunction = null,
  varifieFunction = null
) => {
  let res = null;
  await api
    .get("/questionnaire/questionnaire-status")
    .then(response => {
      if (setFunction) setFunction(response.data);
      if (varifieFunction) varifieFunction(true);
      res = response.data;
      return response.data;
    })
    .catch(error => {
      console.error("Error getting test timeout:", error);
    });
  return res;
};

const requestIsToiletBrakeAccepted = (
  setFunction = null,
  varifieFunction = null
) => {
  api
    .get("/questionnaire/at-toilet-brake")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (setFunction) setFunction(response.data.isAtToiletBrake);
      if (varifieFunction) varifieFunction(true);
      return response.data;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting test timeout:", error);
    });
};

// Request chapter level from server
const requestChapterLevel = (setFunction = null, varifieFunction = null) => {
  api
    .get("/questionnaire/chapter-level")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (setFunction) setFunction(response.data.chapterLevel);
      if (varifieFunction) varifieFunction(true);
      return response.data.chapterLevel;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting chapter level:", error);
    });
};

const requestRaisedHandQuestions = (
  setFunction = null,
  varifieFunction = null
) => {
  api
    .get("/questionnaire/raised-hand-questions")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (setFunction) setFunction(response.data.raiseHandQuestions);
      if (varifieFunction) varifieFunction(true);
      return response.data.raiseHandQuestions;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting raised hand questions:", error);
    });
};

const requestTestName = (setFunction = null, varifieFunction = null) => {
  api
    .get("/questionnaire/test-name")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (setFunction) setFunction(response.data.testName);
      if (varifieFunction) varifieFunction(true);
      return response.data.testName;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting test name:", error);
      if (setFunction) setFunction(-1);
      return -1;
    });
};

const requestChapterName = (setFunction = null, varifieFunction = null) => {
  api
    .get("/questionnaire/chapter-name")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (setFunction) setFunction(response.data.chapterName);
      if (varifieFunction) varifieFunction(true);
      return response.data.chapterName;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting test name:", error);
      if (setFunction) setFunction(-1);
      return -1;
    });
};

const requestStopMessageBeforeChapter = (
  setFunction = null,
  varifieFunction = null
) => {
  api
    .get("/questionnaire/stop-message-before-chapter")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (setFunction) setFunction(response.data.stopMessageBeforeChapter);
      if (varifieFunction) varifieFunction(true);
      return response.data.stopMessageBeforeChapter;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting stop message before chapter:", error);
    });
};

const requestIsSameTest = (
  path,
  setFunction = null,
  varifieFunction = null
) => {
  const endocedPath = encodeURIComponent(path);
  api
    .get("/questionnaire/same-test?path=" + endocedPath)
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (setFunction) setFunction(response.data.isSameTest);
      if (varifieFunction) varifieFunction(true);
      return response.data.isSameTest;
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error getting test level:", error);
      window.close();
    });
};

const requestChapterHasRefrencePage = async (
  setFunction = null,
  varifieFunction = null
) => {
  try {
    const response = await api
      .get("/questionnaire/chapter-has-refrence-page")
      .then(response => handleUnauthorizedAccess(response));
    if (setFunction) setFunction(response.data.chapterHasRefrencePage);
    if (varifieFunction) varifieFunction(true);

    return response.data.chapterHasRefrencePage;
  } catch (error) {
    history.push("/");
    window.location.reload();
    console.error("Error getting chapter has refrence page:", error);
    return null;
  }
};

export {
  requestAllQuestions,
  requestChapterHasRefrencePage,
  requestChapterLevel,
  requestChapterName,
  requestChapterTimeout,
  requestIsSameTest,
  requestIsToiletBrakeAccepted,
  requestQuestion,
  requestQuestionIndex,
  requestQuestionnaireStatus,
  requestRaisedHandQuestions,
  requestRefrences,
  requestRefrencesTimeout,
  requestResponses,
  requestShouldEndTest,
  requestStartOfChapterIndex,
  requestStopMessageBeforeChapter,
  requestTestName
};
