import iconv from "iconv-lite";
import React, { useState } from "react";
const encodeing = "utf-8";
function UpdateUsers(sendUsersFunction) {
  const [content, setContent] = useState(null);

  const csvToDict = csv => {
    const lines = csv.trim().split("\n");
    const users = lines.slice(1).map(line => {
      const values = line.split(",");
      return {
        name: values[0],
        id: values[1],
        username: values[1],
        cookie: null,
        expires: values[2].replace(/\r/g, "")
      };
    });
    return { users };
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = event => {
      const fileContentArray = new Uint8Array(event.target.result);
      const fileContent = iconv.decode(fileContentArray, encodeing);
      setContent(csvToDict(fileContent));
    };
    reader.readAsArrayBuffer(selectedFile);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (content) {
      const updatedContent = {
        users: content.users.map(user => ({
          ...user,
          expires: user.expires.replace(/\r/g, "")
        }))
      };
      sendUsersFunction(updatedContent);
    } else {
      console.log("No file selected");
    }
  };
  const inputKey = content ? content.users.length : "default";

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Upload users CSV file:
        <input
          key={inputKey}
          type="file"
          onClick={e => (e.target.value = null)}
          onChange={handleFileChange}
          accept=".csv"
        />
      </label>
      <button type="submit">Update Users</button>
    </form>
  );
}

export default UpdateUsers;
