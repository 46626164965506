// set up the websocket connection

import { createContext } from "react";
import history from "./history";
import { operational } from "./api";

const protectedReferences = [];
const host = operational ? "amnon.westeurope.cloudapp.azure.com" : "localhost";

class ClientWebSocketService {
  constructor(userID, cookie, port) {
    this.userID = userID;
    this.cookie = cookie;

    this.ws = new WebSocket(`wss://${host}:${port}`);
    this.ws.addEventListener("message", this.handleMessage); // bind the function to the instance
    // open websocket
    this.ws.addEventListener("open", () => { });

    // log error if it happens
    this.ws.addEventListener("error", error => {
      console.error("WebSocket error:", error);
    });

    // initialize an empty object to store the bound messages and functions
    this.boundMessages = {};
  }

  addProtectedReference = protectedReference => {
    protectedReferences.push(protectedReference);
  };

  // function that runs when a message is received from the server
  handleMessage = event => {
    console.log("event reciving message:", event.data);
    const eventData = JSON.parse(event.data);

    // checks the message cookie and userID
    if (eventData.cookie !== this.cookie && eventData.userID !== this.userID) {
      console.log("Cookie and userID does not match");
      return;
    }
    console.log("WebSocket message received", eventData);

    console.log("this.boundMessages:", this.boundMessages);
    // check if the received message matches any of the bound messages
    for (const message in this.boundMessages) {
      if (eventData.message === message) {
        // call the bound function
        this.boundMessages[message].map(func => {
          func(eventData);
        });
      }
    }

    // handle other messages
    if (eventData.message === "Go To Refrences") {
      history.push("/questionnaire/start-chapter");
      window.location.reload();
    } else if (eventData.message === "Go To Toilet") {
      // if there are questions in the chapter:
      // history.push("/pause-screen");
      // window.location.reload();
    } else if (eventData.message === "User has finished questionnaire") {
      history.push("/questionnaire/end-questionneer");
      window.location.reload();
    }
  };

  // bind a message to a function
  bindMessage = (message, func) => {
    // if this message is not already bound
    if (!this.boundMessages[message]) {
      this.boundMessages[message] = [func];
    } else {
      // if this message is already bound, add the new function to the array if it doesn't already exist
      // if (!this.boundMessages[message].includes(func)) {
      this.boundMessages[message].push(func);
      // }
    }
  };

  // unbind a message
  unbindMessage = message => {
    if (this.boundMessages && this.boundMessages[message]) {
      delete this.boundMessages[message];
    }
  };

  on = (message, func) => {
    this.bindMessage(message, func);
  };

  off = message => {
    this.unbindMessage(message);
  };

  // send message to the server
  sendMessage = message => {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(
        JSON.stringify({
          message,
          cookie: this.cookie,
          userID: this.userID
        })
      );
    } else {
      console.log("WebSocket is not open");
    }
  };

  disconnect = () => {
    this.ws.close();
  };

  addListener = (event, callback) => {
    this.ws.addEventListener(event, callback);
  };

  removeListener = (event, callback) => {
    this.ws.removeEventListener(event, callback);
  };
}
const WebSocketContext = createContext(null);
export { ClientWebSocketService, WebSocketContext };
