import api from "../../../../services/api";

const postMoveToNextChapter = userID => {
  api.post("/operator/next-chapter", { userID: userID }).then(response => {
    console.log(response.data);
  });
};

const postMoveToNextTest = userID => {
  api.post("/operator/next-test", { userID: userID }).then(response => {
    console.log(response.data);
  });
};

const postAllowToiletBrake = userID => {
  api
    .post("/operator/allow-toilet-brake", { userID: userID })
    .then(response => {
      console.log(response.data);
    });
};

const postAnswerRaisedHandQuestion = (userID, questionID, queueID, answer) => {
  api
    .post("/operator/answered-raised-hand-question", {
      userID: userID,
      questionID: questionID,
      queueID: queueID,
      answer: answer
    })
    .then(response => {
      console.log(response.data);
    });
};

const postCloseUserQueue = (userID, queueID) => {
  api
    .post("/operator/close-user-queue", {
      userID: userID,
      queueID: queueID
    })
    .then(response => {
      console.log(response.data);
    });
};

const postChangeTestTime = (userID, minutes, shouldIncrease) => {
  console.log(
    "extend timt to user: " + userID + " by: " + minutes + " minutes"
  );
  api
    .post("/operator/change-test-time", {
      userID: userID,
      minutes: minutes,
      shouldIncrease: shouldIncrease
    })
    .then(response => {
      console.log(response.data);
    });
};

const postChangeReferencesTime = (userID, minutes, shouldIncrease) => {
  api
    .post("/operator/change-reference-time", {
      userID: userID,
      minutes: minutes,
      shouldIncrease: shouldIncrease
    })
    .then(response => {
      console.log(response.data);
    });
};

const postChangeChapterTime = (userID, minutes, shouldIncrease) => {
  api
    .post("/operator/change-chapter-time", {
      userID: userID,
      minutes: minutes,
      shouldIncrease: shouldIncrease
    })
    .then(response => {
      console.log(response.data);
    });
};

const postMoveToPreviousTest = userID => {
  api.post("/operator/previous-test", { userID: userID }).then(response => {
    console.log(response.data);
  });
};

const postMoveToPreviousChapter = userID => {
  api.post("/operator/previous-chapter", { userID: userID }).then(response => {
    console.log(response.data);
  });
};

const postMoveToChapterRefrences = userID => {
  api
    .post("/operator/previous-refrences", { userID: userID })
    .then(response => {
      console.log(response.data);
    });
};

const postSendMessageToEveryone = message => {
  api
    .post("/operator/send-message-to-everyone", { message: message })
    .then(response => {
      console.log(response.data);
    });
};

const postStopEveryoneTest = () => {
  api.post("/operator/end-all-questionnaires").then(response => {
    console.log(response.data);
  });
};

const postRestartEveryoneTest = () => {
  api.post("/operator/restart-all-questionnaires").then(response => {
    console.log(response.data);
  });
};

const postSendMessageToSomeone = (userID, message) => {
  api
    .post("/operator/send-message-to-someone", {
      userID: userID,
      message: message
    })
    .then(response => {
      console.log(response.data);
    });
};

const postReturnFromToilet = userID => {
  api.post("/operator/return-from-toilet-brake", { userID: userID }).then(response => {
    console.log(response.data);
  });
};

export {
  postAllowToiletBrake,
  postAnswerRaisedHandQuestion,
  postChangeChapterTime,
  postChangeReferencesTime,
  postChangeTestTime,
  postCloseUserQueue,
  postMoveToChapterRefrences,
  postMoveToNextChapter,
  postMoveToNextTest,
  postMoveToPreviousChapter,
  postMoveToPreviousTest,
  postRestartEveryoneTest,
  postSendMessageToEveryone,
  postSendMessageToSomeone,
  postStopEveryoneTest,
  postReturnFromToilet
};
