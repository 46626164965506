import { Typography } from "@mui/material";
import { debounce } from "lodash"; // Import the debounce function from the lodash library
import { useEffect } from "react";
import { RestrictedResourceLink } from "../RefrencesFetcher/ResourcesRendering/ProtectedResource";
import VideoPlayer from "../RefrencesFetcher/ResourcesRendering/VideoRendering";

const RefrencesRender = refrences => {
  const handleResize = debounce(() => {
    const videoElements = document.querySelectorAll("video");

    videoElements.forEach(videoElement => {
      if (videoElement) {
        const { clientWidth, clientHeight } = videoElement;
        videoElement.setAttribute("width", clientWidth);
        videoElement.setAttribute("height", clientHeight);
      }
    });
  }, 100); // Set the debounce delay to 100ms

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const SwitchRender = refrence => {
    if (refrence["refrence_html"] !== undefined) {
      return (
        <div dangerouslySetInnerHTML={{ __html: refrence["refrence_html"] }} />
      );
    } else if (refrence["type"] && refrence["type"] === "pdf") {
      return (
        <div>
          <RestrictedResourceLink
            refrenceType={"pdf"}
            resourcePath={refrence["path"]}
            name={refrence["name"]}
            otherOptions={{ page: 1 }}
          />
        </div>
      );
      // return <div> {IframeViewer(refrence["path"], refrence["name"])} </div>;
    } else if (refrence["type"] && refrence["type"] === "video") {
      return <VideoPlayer path={refrence["path"]} />;
      // let videoFullPath = process.env.PUBLIC_URL + refrence["image_path"];
      // if (refrence["path"].startsWith(".")) {
      //   videoFullPath =
      //     process.env.PUBLIC_URL + refrence["path"].substring(1);
      // }
      // return (
      //   <div>
      //     <video
      //       width="640"
      //       height="480"
      //       className="my-element"
      //       controls
      //       controlsList="nofullscreen nodownload"
      //     >
      //       <source src={videoFullPath} type="video/mp4" />
      //     </video>
      //   </div>
      // );
    } else if (refrence["image_path"] !== undefined) {
      let imageFullPath = process.env.PUBLIC_URL + refrence["image_path"];
      if (refrence["image_path"].startsWith(".")) {
        imageFullPath =
          process.env.PUBLIC_URL + refrence["image_path"].substring(1);
      }
      return (
        <div
          className="image-container"
          style={{ float: "right", marginBottom: "10px" }}
        >
          <img src={imageFullPath} alt="refrence" />
        </div>
      );
    } else if (refrence["type"] && refrence["type"] === "html") {
      // return <div> {IframeViewer(refrence["path"], refrence["name"])} </div>;
      return (
        <div>
          <RestrictedResourceLink
            refrenceType={"html"}
            resourcePath={refrence["path"]}
            name={refrence["name"]}
          />
        </div>
      );
    }
  };

  return (
    <div style={{ textAlign: "right" }}>
      <br />
      <br />
      <Typography variant="h4" component="h1" align="right" gutterBottom>
        חומרי עזר
      </Typography>
      <div>
        {Array.isArray(refrences) &&
          refrences.map(refrence => (
            <div key={refrence["name"]}>{SwitchRender(refrence)}</div>
          ))}
      </div>
    </div>
  );
};

export default RefrencesRender;
