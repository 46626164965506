import Button from "@mui/material/Button";
import { useContext, useEffect, useState } from "react";
import { WebSocketContext } from "../../../../../services/ClientWebSocketService";
import { requestPdfPagesAmount } from "../ResourcesAPI/ResourcesGet";
import { ProtectedReference } from "./ProtectedResource";
import { operational } from "../../../../../services/api";

const host = operational ? "amnon.westeurope.cloudapp.azure.com" : "localhost";

const PDFViewer = () => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [first, setFirst] = useState(true);
  const webSocket = useContext(WebSocketContext);

  const urlParams = new URLSearchParams(window.location.search);
  const pdf = urlParams.get("path");
  const page = urlParams.get("page");
  const encodedResourcePath = encodeURIComponent(pdf);
  const pdfPath = `https://${host}/api/questionnaire/get-pdf-file?pdf=${encodedResourcePath}&page=${page}`;

  useEffect(() => {
    if (first) {
      const urlParams = new URLSearchParams(window.location.search);
      const page = urlParams.get("page");
      requestPdfPagesAmount(pdf, setNumPages);
      setCurrentPage(parseInt(page));
      setFirst(false);
    }
  }, [first]);

  useEffect(() => {
    if (!numPages) {
      requestPdfPagesAmount(pdf, setNumPages);
    }
  }, [numPages]);

  useEffect(() => {
    if (!currentPage) {
      const page = urlParams.get("page");
      setCurrentPage(parseInt(page));
    }
  }, [currentPage]);

  const onPreviousPage = () => {
    if (currentPage <= 1) return;
    setCurrentPage(currentPage => currentPage - 1);
    const newPage = currentPage - 1;
    const newUrl = `/questionnaire/pdf-file?path=${encodeURIComponent(
      pdf
    )}&page=${newPage}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
    window.scrollTo(0, 0);
  };

  const onNextPage = () => {
    if (currentPage >= numPages) return;
    setCurrentPage(currentPage => currentPage + 1);
    const newPage = currentPage + 1;
    const newUrl = `/questionnaire/pdf-file?path=${encodeURIComponent(
      pdf
    )}&page=${newPage}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
    // set the view to the top of the page
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <ProtectedReference
        refrenceRender={
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start", // Align content at the top
                overflowY: "auto", // Enable vertical scrolling if content overflows
                marginTop: "50px" // Adjust this value as needed
              }}
            >
              <img
                src={`${pdfPath}`}
                alt="PDF"
                style={{ objectFit: "contain", maxWidth: "100%" }} // Adjust image styling as needed
              />
            </div>
            <div
              style={{
                marginTop: "20px",
                position: "fixed",
                top: "0",
                left: "0"
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px"
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onNextPage}
                    disabled={currentPage >= numPages}
                    style={{ marginLeft: "10px" }}
                  >
                    העמוד הבא
                  </Button>
                  <p style={{ margin: "0 10px" }}>
                    עמוד {currentPage} מתוך {numPages}
                  </p>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onPreviousPage}
                    disabled={currentPage <= 1}
                    style={{ marginRight: "10px" }}
                  >
                    העמוד הקודם
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default PDFViewer;
