import React from "react";
import { ProtectedReference } from "./ProtectedResource";
import { operational } from "../../../../../services/api";

const host = operational ? "amnon.westeurope.cloudapp.azure.com" : "localhost";

const VideoPlayer = ({ path = null }) => {
  const urlParams = new URLSearchParams(window.location.search);
  let fullPath = path;
  if (!fullPath) {
    fullPath = urlParams.get("path");
  }
  const encodedResourcePath = encodeURIComponent(fullPath);

  const videoPath = `https://${host}/api/questionnaire/video-stream?path=${encodedResourcePath}`;

  return (
    <div>
      <ProtectedReference
        resourcePath={encodedResourcePath}
        refrenceRender={
          <div>
            <video
              width="640"
              height="480"
              className="my-element"
              controls
              controlsList="nofullscreen nodownload"
            >
              <source src={videoPath} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        }
      />
    </div>
  );
};

export default VideoPlayer;
