import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import history from "../../services/history";
import { postBackFromToiletBrake } from "./Questionnaire/QuestionneerAPI/QuestionneerPost";

function PauseScreen() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const broadcastChannel = new BroadcastChannel("toiletChannel");
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleBroadcastMessage = event => {
      if (event.data.message === "Back From Toilet") {
        history.back();
      }
    };

    broadcastChannel.addEventListener("message", handleBroadcastMessage);

    return () => {
      broadcastChannel.removeEventListener("message", handleBroadcastMessage);
    };
  }, [broadcastChannel]);

  const handleGoingBack = () => {
    postBackFromToiletBrake();
    broadcastChannel.postMessage({ message: "Back From Toilet" });

    history.back();
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "background.default"
      }}
    >
      <Typography variant="h3" sx={{ mb: 4, color: "text.primary" }}>
        מבחן בהפסקה
      </Typography>
      <Typography
        variant="h1"
        sx={{ fontSize: "2rem", fontWeight: "bold", color: "text.primary" }}
      >
        {currentTime.toLocaleTimeString()}
      </Typography>
      <Button
        variant="contained"
        sx={{
          mt: 4,
          bgcolor: "primary.main",
          color: "primary.contrastText",
          "&:hover": { bgcolor: "primary.dark" }
        }}
        onClick={() => {
          handleGoingBack();
        }}
      >
        חזרה למבחן
      </Button>
    </Box>
  );
}

export default PauseScreen;
