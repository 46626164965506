import api from "../../../../services/api";

const requestUsers = (setFunction = null, varifieFunction = null) => {
  api
    .get("/operator/users")
    .then(response => {
      if (setFunction) setFunction(response.data.users);
      if (varifieFunction) varifieFunction(true);

      return response.data.users;
    })
    .catch(error => {});
};

export { requestUsers };
