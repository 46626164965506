import { Button } from "@mui/material";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useState } from "react";
import {
  postMoveToNextChapter,
  postMoveToNextTest
} from "../../Operator/OperatorAPI/OperatorPost";

const UserSkipDialog = ({ onClose, user }) => {
  const [skipType, setSkipType] = useState("");
  const handleSkipTest = userId => {
    postMoveToNextTest(userId);
    console.log(`Skipping test for user with id ${userId}`);
  };

  const handleSkipChapter = userId => {
    postMoveToNextChapter(userId);
    console.log(`Skipping chapter for user with id ${userId}`);
  };
  const handleSkipTypeSelect = (type, user) => {
    setSkipType(type);
    if (type === "test") {
      handleSkipTest(user.id);
    } else if (type === "chapter") {
      handleSkipChapter(user.id);
    }
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{`Skip for ${user?.name}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to skip {user?.name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSkipTypeSelect("test", user)}
          color="primary"
        >
          Skip Test
        </Button>
        <Button
          onClick={() => handleSkipTypeSelect("chapter", user)}
          color="primary"
        >
          Skip Chapter
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UserSkipDialog;
