import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { operational } from "../../../../services/api";

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh"
});

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px"
});

const TextFieldStyled = styled(TextField)({
  width: "300px"
});

const ButtonStyled = styled(Button)({
  width: "200px"
});

const host = operational ? "amnon.westeurope.cloudapp.azure.com" : "localhost";

const SimpleRegister = registerType => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      // Send registration data to the server
      const response = await fetch(
        `https://${host}/api/${registerType.registerType}/register`,
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ username, password })
        }
      );

      // Get the cookie from the response
      response.json().then(data => {
        const cookie = data.cookie;
        // Assuming registration is successful

        if (data.message === "Registration successful") {
          // localStorage.setItem("cookie", cookie); // Set the cookie in localStorage

          navigate(`/${registerType.registerType}/manager`); // Redirect to the success page
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormContainer>
      <h1>הרשמה</h1>
      <Form onSubmit={handleSubmit}>
        <TextFieldStyled
          label="Username"
          variant="outlined"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <TextFieldStyled
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <ButtonStyled type="submit" variant="contained" color="primary">
          Register
        </ButtonStyled>
      </Form>
    </FormContainer>
  );
};

export default SimpleRegister;
