// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webcam-container {
    width: 100%;
    /* Adjust height as needed */
    position: absolute;
    /* Ensure relative positioning for absolute children */
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    align-items: flex-end;
    /* Align items to the right */
}

.webcam-real {
    border: 4px solid #9a9797;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Shadow effect */
}

.recording-message {
    width: 40px;
    position: absolute;
    padding: 1px;
    background-color: white;
    border: 3px solid #9a9797;
    border-radius: 30px;
    /* This property creates the curved corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Optional shadow for better visual effect */
    text-align: center;
    font-size: x-small;
}

.error-message {
    position: absolute;
    bottom: 10px;
    /* Adjust positioning */
    right: 10px;
    /* Adjust positioning */
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    /* Adjust padding as needed */
    border-radius: 5px;

    /* Ensure it's above the webcam display */
    font-size: 14px;
    /* Adjust font size as needed */
    white-space: nowrap;
    /* Prevent line wrapping */
}`, "",{"version":3,"sources":["webpack://./src/components/Styles/Webcam.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,4BAA4B;IAC5B,kBAAkB;IAClB,sDAAsD;IACtD,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,qBAAqB;IACrB,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,0CAA0C;IAC1C,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,6CAA6C;IAC7C,0CAA0C;IAC1C,6CAA6C;IAC7C,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,WAAW;IACX,uBAAuB;IACvB,sCAAsC;IACtC,YAAY;IACZ,iBAAiB;IACjB,6BAA6B;IAC7B,kBAAkB;;IAElB,yCAAyC;IACzC,eAAe;IACf,+BAA+B;IAC/B,mBAAmB;IACnB,0BAA0B;AAC9B","sourcesContent":[".webcam-container {\n    width: 100%;\n    /* Adjust height as needed */\n    position: absolute;\n    /* Ensure relative positioning for absolute children */\n    display: flex;\n    flex-direction: column;\n    /* Stack items vertically */\n    align-items: flex-end;\n    /* Align items to the right */\n}\n\n.webcam-real {\n    border: 4px solid #9a9797;\n    border-radius: 10px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n    /* Shadow effect */\n}\n\n.recording-message {\n    width: 40px;\n    position: absolute;\n    padding: 1px;\n    background-color: white;\n    border: 3px solid #9a9797;\n    border-radius: 30px;\n    /* This property creates the curved corners */\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n    /* Optional shadow for better visual effect */\n    text-align: center;\n    font-size: x-small;\n}\n\n.error-message {\n    position: absolute;\n    bottom: 10px;\n    /* Adjust positioning */\n    right: 10px;\n    /* Adjust positioning */\n    background-color: rgba(255, 0, 0, 0.8);\n    color: white;\n    padding: 5px 10px;\n    /* Adjust padding as needed */\n    border-radius: 5px;\n\n    /* Ensure it's above the webcam display */\n    font-size: 14px;\n    /* Adjust font size as needed */\n    white-space: nowrap;\n    /* Prevent line wrapping */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
