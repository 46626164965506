import {
  Button,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UserTimingDialog from "../PageUtils/Operator/UserChangeTimeDialog";
import UserMessageDialog from "../PageUtils/Operator/UserMessageDialog";
import UserQuestionsDialog from "../PageUtils/Operator/UserQuestionsDialog";
import UserReturnDialog from "../PageUtils/Operator/UserReturnDialog";
import UserSkipDialog from "../PageUtils/Operator/UserSkipDialog";
import TopNav from "../PageUtils/User/TopNav/TopNav";
import { requestUsers } from "./OperatorAPI/OperatorGet";
import {
  postAllowToiletBrake,
  postCloseUserQueue,
  postRestartEveryoneTest,
  postSendMessageToEveryone,
  postStopEveryoneTest
} from "./OperatorAPI/OperatorPost";

const Root = styled.div`
  margin: 2px;
`;

const Title = styled(Typography)`
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
`;

const ListItemStyled = styled(ListItem)`
  cursor: pointer;
  &:nth-child(even) {
    ${({ raiseHand }) => !raiseHand && `background-color: #f5f5f5;`}
  }
  &:hover {
    background-color: #e0e0e0;
  }
  ${({ needsToiletbrake }) =>
    needsToiletbrake &&
    `
        color: white;
    `}
  ${({ raiseHand }) =>
    raiseHand &&
    `
        background-color: yellow;
    `}
`;

const PAGE_SIZE = 10;

const OperatorPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [acceptAutomaticallyToiletBrake, setAcceptAutomaticallyToiletBrake] =
    useState(true);
  const [openQuestionDialog, setOpenQuestionDialog] = useState(false);
  const [openSkipDialog, setOpenSkipDialog] = useState(false);
  const [openTimingDialog, setOpenTimingDialog] = useState(false);
  const [openReturnDialog, setOpenReturnDialog] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); // Step 1: Add searchQuery state variable

  const updateUsers = users => {
    const usersWithoutNull = users.filter(
      user => user !== null && user.stoppedQuestionnaire != true
    );
    setUsers(usersWithoutNull);
  };
  useEffect(() => {
    if (acceptAutomaticallyToiletBrake) {
      users.forEach(user => {
        if (user.toilet_brake && !user.at_the_toilet) {
          acceptToiletbrake(user);
        }
      });
    }
  }, [users]); // add acceptAutomaticallyToiletBrake as a dependency

  useEffect(() => {
    const intervalId = setInterval(() => {
      requestUsers(updateUsers);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []); // add acceptAutomaticallyToiletBrake as a dependency

  const handleClose = () => {
    setOpenQuestionDialog(false);
    setOpenSkipDialog(false);
    setOpenTimingDialog(false);
    setOpenReturnDialog(false);
    setOpenMessageDialog(false);
    setSelectedUser(null);
  };

  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSearch = event => {
    setSearchQuery(event.target.value); // Step 3: Update searchQuery state variable
  };

  const acceptToiletbrake = user => {
    try {
      postAllowToiletBrake(user.id);
    } catch (e) {
      console.log(e);
    }
  };

  const sendMessage = () => {
    // Send message to server
    postSendMessageToEveryone(message);
    setMessage("");
  };

  const readAllMessages = user => {
    // for all questions queue postCloseUserQueue
    user.raise_hand_questions.forEach(queue => {
      if (queue.isOpen) {
        postCloseUserQueue(user.id, queue.id);
      }
    });
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleStopTest = () => {
    postStopEveryoneTest();
  };

  const handleRestartTest = () => {
    postRestartEveryoneTest();
  };
  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const usersInPage = filteredUsers.sort((a, b) =>
    a.raise_hand === b.raise_hand ? 0 : a.raise_hand ? -1 : 1
  );
  const usersPerPage = 20;
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = usersInPage.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <>
      {TopNav()}
      <Root>
        <Title variant="h4">Operator Page</Title>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography>Accept Toilet Brake Automatically</Typography>
          <div
            style={{
              display: "flex",
              alignItems: "left",
              marginRight: "150px"
            }}
          >
            <Switch
              checked={acceptAutomaticallyToiletBrake}
              onChange={event => {
                console.log("change accept auto to:", event.target.checked);
                setAcceptAutomaticallyToiletBrake(event.target.checked);
              }}
            />
          </div>

          <div style={{ marginRight: "150px" }}>
            <TextField
              margin="dense"
              type="text"
              label="שלח הודעה לכולם"
              value={message}
              fullWidth
              dir="rtl"
              onChange={event => setMessage(event.target.value)}
            />
            <Button color="primary" onClick={sendMessage}>
              שלח הודעה לכולם
            </Button>
          </div>
          <div style={{ marginRight: "20px", marginBottom: "40px" }}>
            <TextField
              margin="dense"
              type="text"
              label="Search User"
              value={searchQuery}
              fullWidth
              dir="rtl"
              onChange={handleSearch}
            />
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Button color="primary" onClick={handleStopTest}>
              Stop Everyone Test
            </Button>
            <Button color="primary" onClick={handleRestartTest}>
              Start Everyone Test
            </Button>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <Button
              color="primary"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous Page
            </Button>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <Button
              color="primary"
              onClick={handleNextPage}
              disabled={indexOfLastUser >= usersInPage.length}
            >
              Next Page
            </Button>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <Typography>
              Page {currentPage} of{" "}
              {Math.ceil(usersInPage.length / usersPerPage)}
            </Typography>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Typography>Amount of users: {usersInPage.length}</Typography>
          </div>
        </div>
        <List>
          {currentUsers.map(user => (
            <ListItemStyled
              key={user.id}
              needsToiletbrake={
                user.toilet_brake &&
                !user.at_the_toilet &&
                !acceptAutomaticallyToiletBrake
              }
              raiseHand={user.raise_hand}
            >
              <ListItemText
                primary={user.name}
                secondary={`Test: ${user.questionnaire_test}, Chapter: ${
                  user.questionnaire_chapter
                }, Question Number: ${
                  user.questionnaire_question_index_in_chapter
                }, Chapter Time Left: ${formatTime(
                  user.chapter_time_left
                )}, Test Time Left: ${formatTime(user.test_time_left)}`}
              />
              {user.toilet_brake &&
                !user.at_the_toilet &&
                !acceptAutomaticallyToiletBrake && (
                  <Button
                    onClick={() => {
                      acceptToiletbrake(user);
                    }}
                    color="primary"
                  >
                    Allow Toilet brake
                  </Button>
                )}
              {
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedUser(user);
                    setOpenTimingDialog(true);
                    setOpenSkipDialog(false);
                    setOpenReturnDialog(false);
                    setOpenQuestionDialog(false);
                    setOpenMessageDialog(false);
                  }}
                >
                  Timing User
                </Button>
              }
              {
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedUser(user);
                    setOpenQuestionDialog(true);
                    setOpenTimingDialog(false);
                    setOpenSkipDialog(false);
                    setOpenReturnDialog(false);
                    setOpenMessageDialog(false);
                  }}
                >
                  Answer Questions
                </Button>
              }
              {
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedUser(user);
                    setOpenSkipDialog(true);
                    setOpenQuestionDialog(false);
                    setOpenReturnDialog(false);
                    setOpenTimingDialog(false);
                    setOpenMessageDialog(false);
                  }}
                >
                  Skip User
                </Button>
              }
              {
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedUser(user);
                    setOpenReturnDialog(true);
                    setOpenTimingDialog(false);
                    setOpenSkipDialog(false);
                    setOpenQuestionDialog(false);
                    setOpenMessageDialog(false);
                  }}
                >
                  Return User
                </Button>
              }
              {
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedUser(user);
                    console.log("open message dialog for user:", user);
                    setOpenMessageDialog(true);
                    setOpenReturnDialog(false);
                    setOpenTimingDialog(false);
                    setOpenSkipDialog(false);
                    setOpenQuestionDialog(false);
                  }}
                >
                  Send Message
                </Button>
              }
              {
                <Button
                  color="primary"
                  onClick={() => {
                    readAllMessages(user);
                  }}
                >
                  read all messages
                </Button>
              }
              {selectedUser && (
                <>
                  {openQuestionDialog && (
                    <UserQuestionsDialog
                      onClose={handleClose}
                      user={selectedUser}
                    />
                  )}
                  {openReturnDialog && (
                    <UserReturnDialog
                      onClose={handleClose}
                      user={selectedUser}
                    ></UserReturnDialog>
                  )}
                  {openSkipDialog && (
                    <UserSkipDialog
                      onClose={handleClose}
                      user={selectedUser}
                    ></UserSkipDialog>
                  )}
                  {openTimingDialog && (
                    <UserTimingDialog
                      onClose={handleClose}
                      user={selectedUser}
                    ></UserTimingDialog>
                  )}
                  {openMessageDialog && (
                    <UserMessageDialog
                      onClose={handleClose}
                      user={selectedUser}
                    ></UserMessageDialog>
                  )}
                </>
              )}

              {user.at_the_toilet && (
                <span
                  role="img"
                  aria-label="toilet-emoji"
                  style={{ fontSize: "2em" }}
                >
                  🚽
                </span>
              )}
            </ListItemStyled>
          ))}
        </List>
      </Root>
    </>
  );
};

export default OperatorPage;
