/**
 * this class will be used to display the ending of a chapter, it will display the option to pass to the next chapter
 * or to return to view the previous chapter
 * @fileoverview ClientTestEnding Component
 *
 */
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useEffect, useState } from "react";
import history from "../../../../services/history";
import HeadLine from "../../PageUtils/User/TestHeadLine";
import TopNav from "../../PageUtils/User/TopNav/TopNav";
import {
  requestChapterHasRefrencePage,
  requestChapterName,
  requestQuestion
} from "../QuestionneerAPI/QuestionneerGet";
import { postMoveToNextChapter } from "../QuestionneerAPI/QuestionneerPost";

const ClientChapterEnding = () => {
  const [loading, setLoading] = useState(false);
  const [chapterName, setChapterName] = useState("");

  const updateNameFromServer = () => {
    requestChapterName(setChapterName);
  };

  useEffect(() => {
    if (!chapterName) {
      updateNameFromServer();
    }
  }, [chapterName]);

  // Define an effect to move to the next chapter
  const nextChapter = async () => {
    setLoading(true);
    await postMoveToNextChapter(chapterName);
    const hasOnlyRefrencesPage = await requestChapterHasRefrencePage();
    if (hasOnlyRefrencesPage) {
      history.push("/questionnaire/start-chapter");
      window.location.reload();
    } else {
      // results = null;
      requestQuestion();
      history.push("/questionnaire");
      window.location.reload();
    }
    setLoading(false);
  };

  // Define an effect to go back after finishing a chapter
  const previousChapter = () => {
    setLoading(true);
    history.push("/questionnaire");
    window.location.reload();
    setLoading(false);
  };

  return (
    <>
      {TopNav(true, true)}
      {HeadLine()}
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center", // add text-align center
          mt: -5
        }}
      >
        <Typography variant="h2" sx={{ mb: 2 }}>
          הפרק נגמר
        </Typography>
        {/* <Typography variant="h3" sx={{ mb: 4 }}>
          !במעבר לפרק הבא לא יהיה ניתן לחזור לפרק הקודם. שימו לב
        </Typography> */}
        {/* <Button
          variant="contained"
          disabled={loading || (gotFirstIndex && questionIndex == 0)}
          onClick={() => {
            setLoading(true);
            previousChapter();
          }}
          sx={{ mb: 2 }}
        >
          עיון בשאלות
        </Button> */}
        <Button
          variant="contained"
          disabled={loading}
          onClick={() => {
            setLoading(true);
            nextChapter();
          }}
        >
          פרק הבא
        </Button>
      </Container>
    </>
  );
};

export default ClientChapterEnding;
