import userLogger from "../../services/userLogger";
import {
  postQuestionIndex,
  postQuestionnaireResponse
} from "./Questionnaire/QuestionneerAPI/QuestionneerPost";

import {
  requestQuestion,
  requestQuestionIndex,
  requestResponses
} from "./Questionnaire/QuestionneerAPI/QuestionneerGet";

const updateQuestionIndex = (eventData, setQuestionIndex) => {
  setQuestionIndex(eventData.data);
};

// send question index to server
const sendQuestionIndex = async (
  questionIndex,
  setQuestionIndex,
  questions
) => {
  console.log("sendQuestionIndex", questionIndex);
  if (questions && questionIndex >= 0 && questionIndex < questions.length) {
    setQuestionIndex(questionIndex);

    await postQuestionIndex(questionIndex);
  } else {
    userLogger.log("index out of range");
  }
};

// send questionnaire response to server
const sendQuestionnaireResponse = async (
  userResponses,
  testName,
  chapterName
) => {
  await postQuestionnaireResponse(userResponses, testName, chapterName);
};

const updateQuestionIndexFromServer = async (
  setQuestionIndex,
  setGotFirstIndex
) => {
  return await requestQuestionIndex(setQuestionIndex, setGotFirstIndex);
};

// all the questions from server are recived with index, order them by it
const setQuestionsOrdered = (questions, setQuestions) => {
  const orderedQuestions = questions.sort((a, b) => {
    return a.questionIndex - b.questionIndex;
  });
  setQuestions(orderedQuestions);
};
const addUserResponses = async (
  userResponses,
  questionIndex,
  setUserResponses
) => {
  return await requestResponses(
    saveUserResponses(userResponses, questionIndex, setUserResponses)
  );
};

const saveUserResponses = (userResponses, questionIndex, setUserResponses) => {
  return responses => {
    let updatedResponses = [];
    // order the responses by questionIndex that each response has
    const orderedResponses = responses.sort((a, b) => {
      return a.questionIndex - b.questionIndex;
    });
    console.log("orderedResponses:", orderedResponses);

    if (orderedResponses && orderedResponses.length > 0) {
      updatedResponses = orderedResponses.filter(response => response !== null);
    } else {
      updatedResponses = userResponses.filter(response => response !== null);
    }
    setStartTimeForAnswer(questionIndex, updatedResponses, setUserResponses);
  };
};

const saveNewQuestion = (setQuestions, questions) => {
  return question => {
    const newQuestions = [...questions, question];
    setQuestions(newQuestions);
  };
};

const addNewQuestion = async (
  currentQuestion,
  setQuestions,
  questions,
  shouldJumpPages = true
) => {
  console.log("current question:", currentQuestion);
  const response = await requestQuestion(
    !currentQuestion ? null : currentQuestion.id,
    saveNewQuestion(setQuestions, questions),
    () => {},
    shouldJumpPages
  );

  console.log("addNewQuestion response", response);
  return response;
};

const handlePreviousQuestion = async (
  questionIndex,
  userResponses,
  testName,
  chapterName,
  questions,
  setUserResponses,
  setQuestionIndex
) => {
  if (userResponses[questionIndex] && userResponses[questionIndex].response) {
    await sendQuestionnaireResponse(userResponses, testName, chapterName);
  }
  const newIndex = questionIndex - 1;
  setStartTimeForAnswer(newIndex, userResponses, setUserResponses);
  await sendQuestionIndex(newIndex, setQuestionIndex, questions);
};

const handleQuestionResponse = async (
  response,
  questionIndex,
  userResponses,
  setUserResponses,
  updateResponsesFromServer = null
) => {
  // if userResponses is not iterable, fix responses if possibale or skip.
  if (!userResponses || (userResponses.length == 0 && questionIndex > 0)) {
    if (updateResponsesFromServer) {
      userResponses = await updateResponsesFromServer();
    } else {
      return;
    }
  }

  const updatedResponses = [...userResponses];
  updatedResponses[questionIndex] = {
    response,
    questionIndex: questionIndex
  };
  setUserResponses(updatedResponses);
};

const handleNextQuestion = async (
  gotFirstIndex,
  userResponses,
  questionIndex,
  questions,
  setGotFirstIndex,
  setQuestionIndex,
  setQuestions,
  testName,
  chapterName,
  setUserResponses,
  shouldJumpPages = true
) => {
  let response = null;
  if (!gotFirstIndex) {
    userLogger.log(`User must get first index before moving on`);
    return;
  }
  if (
    !userResponses ||
    !userResponses[questionIndex] ||
    !userResponses[questionIndex].response ||
    userResponses[questionIndex].response.response == ""
  ) {
    userLogger.log(`User $(user_id) must respond to question before moving on`);
    return;
  } else {
    console.log(`question index: ${questionIndex}`);
    console.log(`user response: ${userResponses[questionIndex].response}`);
  }

  await sendQuestionnaireResponse(userResponses, testName, chapterName);

  if (questions && questionIndex > questions.length - 2) {
    response = await addNewQuestion(
      questions.length > 0 ? questions[questionIndex] : null,
      setQuestions,
      questions,
      shouldJumpPages
    );

    setGotFirstIndex(false);
    updateQuestionIndexFromServer();
  } else {
    await bumpQuestionIndex(
      questionIndex,
      setQuestionIndex,
      userResponses,
      questions,
      setUserResponses
    );
  }
  return response;
};

const updateServerQuestionIndex = async (
  newIndex,
  setQuestionIndex,
  questions
) => {
  return await sendQuestionIndex(newIndex, setQuestionIndex, questions);
};

const bumpQuestionIndex = async (
  questionIndex,
  setQuestionIndex,
  userResponses,
  questions,
  setUserResponses
) => {
  const newIndex = questionIndex + 1;
  await updateServerQuestionIndex(newIndex, setQuestionIndex, questions);
  setStartTimeForAnswer(newIndex, userResponses, responses => {
    setQuestionIndex(newIndex);
    setUserResponses(responses);
  });
};

const setStartTimeForAnswer = (index, userResponses, setUserResponses) => {
  const updatedResponses = [...userResponses];
  const questionResponse = updatedResponses[index];
  const startTime = Date.now();
  updatedResponses[index] = {
    ...questionResponse,
    startTime: startTime,
    questionIndex: index
  };
  console.log("set start time for answer, updatedResponses:", updatedResponses);
  setUserResponses(updatedResponses);
};
export {
  addNewQuestion,
  addUserResponses,
  bumpQuestionIndex,
  handleNextQuestion,
  handlePreviousQuestion,
  handleQuestionResponse,
  sendQuestionIndex,
  sendQuestionnaireResponse,
  setQuestionsOrdered,
  setStartTimeForAnswer,
  updateQuestionIndex,
  updateQuestionIndexFromServer
};
