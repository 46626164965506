import api from "../../../../services/api";

// from formdata send it
const postUpdateUsers = usersJson => {
  // send the usersFormData as a file
  //add here alert that it succeeded probebly will need to import in admin page
  api.post("/admin/update-users", { usersJson: usersJson }).then(response => {
    const response_json = JSON.parse(JSON.stringify(response))
    alert(response_json.status + ": " + response_json.data)
    console.log(response)
  });
};
// from formdata send it
const postUpdateTests = zipPath => {
  // send the the file at zipPath if exists
  const data = new FormData();
  data.append("file", zipPath);
  // send the usersFormData as a post
  api
    .post("/admin/update-tests", data)
    .then(response => { })
    .catch(error => {
      console.log("error", error);
    });
};

// this function get user results and load it to the server and to the database
const postLoadUsersFromResults = () => {
  // send the usersFormData as a file
  api.post("/admin/load-users-from-results", {}).then(response => { });
};

const postTestUsersQuestions = () => {
  // send the usersFormData as a file
  api.post("/admin/test-users-questions", {}).then(response => { });
};

const postDeleteResponses = () => {
  // send the usersFormData as a file
  api.post("/admin/delete-responses", {}).then(response => { });
};

const postDeleteAttendedUsers = () => {
  // send the usersFormData as a file
  api.post("/admin/delete-attended-users", {}).then(response => { });
};

export {
  postLoadUsersFromResults,
  postTestUsersQuestions,
  postUpdateTests,
  postUpdateUsers,
  postDeleteResponses,
  postDeleteAttendedUsers
};
