/**
 * this class will be used to display the ending of the test
 * @fileoverview ClientTestEnding Component
 *
 */
import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import history from "../../../../services/history";
import HeadLine from "../../PageUtils/User/TestHeadLine";
import TopNav from "../../PageUtils/User/TopNav/TopNav";
import {
  requestChapterHasRefrencePage,
  requestQuestion,
  requestTestName
} from "../QuestionneerAPI/QuestionneerGet";
import { postMoveToNextTest } from "../QuestionneerAPI/QuestionneerPost";
import { Container, StyledButton } from "../styles";

const { Title } = Typography;

const ClientTestEnding = () => {
  const [loading, setLoading] = useState(false);
  const [testName, setTestName] = useState("");

  const updateNameFromServer = () => {
    requestTestName(setTestName);
  };

  useEffect(() => {
    if (!testName) {
      updateNameFromServer();
    }
  }, [testName]);

  // Define an effect to move to the next test
  const nextTest = async () => {
    setLoading(true);
    await postMoveToNextTest(testName);
    const hasOnlyRefrencesPage = await requestChapterHasRefrencePage();

    if (hasOnlyRefrencesPage) {
      history.push("/questionnaire/start-chapter");
      window.location.reload();
    } else {
      requestQuestion();
      history.push("/questionnaire");
      window.location.reload();
    }
    setLoading(false);
  };

  // Define an effect to go back after finishing a chapter
  const previousChapter = () => {
    setLoading(true);
    history.push("/questionnaire");
    window.location.reload();
    setLoading(false);
  };

  return (
    <>
      {TopNav(true, true)}
      {HeadLine()}
      <Container>
        <Title level={3}>!המבחן נגמר</Title>
        <StyledButton
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={() => {
            setLoading(true);
            nextTest();
          }}
        >
          למבחן הבא
        </StyledButton>
        {/* <StyledButton
          onClick={() => {
            setLoading(true);
            previousChapter();
          }}
          disabled={loading || (gotFirstIndex && questionIndex == 0)}
        >
          עיון בשאלות
        </StyledButton> */}
      </Container>
    </>
  );
};

export default ClientTestEnding;
