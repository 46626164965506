import { Button, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import history from "../../../../services/history";
import HeadLine from "../../PageUtils/User/TestHeadLine";
import TopNav from "../../PageUtils/User/TopNav/TopNav";
import {
  requestQuestion,
  requestRefrences,
  requestRefrencesTimeout,
  requestStopMessageBeforeChapter
} from "../QuestionneerAPI/QuestionneerGet";
import RefrencesRender from "./RefrencesRender";
import StopDialog from "./StopDialog";

const OnlyRefrencesRender = () => {
  // Define state variables for the component
  const [loading, setLoading] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const [refrences, setRefrences] = useState([]);
  const [refrencesTimer, setRefrencesTimer] = useState(0);
  const [workingRefrencesTimer, setWorkingRefrencesTimer] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [stopMessageBeforeChapter, setStopMessageBeforeChapter] =
    useState(null);
  // Define an effect to request all questions from the server on the first run of the component
  useEffect(() => {
    if (firstRun) {
      requestStopMessageBeforeChapter(setStopMessageBeforeChapter);
      updateRefrencesFromServer();
      updateRefrencesTimerFromServer();
      setFirstRun(false);
    }
  }, [firstRun]);

  // Define the function handles the refrencesTimer for the client website
  useEffect(() => {
    if (workingRefrencesTimer) {
      const interval = setInterval(() => {
        if (refrencesTimer % 30 === 0) updateRefrencesTimerFromServer();
        else {
          setRefrencesTimer(refrencesTimer => refrencesTimer - 1);
        }
      }, 1000);

      if (refrencesTimer <= 0) {
        clearInterval(interval);
      }

      return () => clearInterval(interval);
    }
  }, [workingRefrencesTimer, refrencesTimer]);

  const updateRefrencesFromServer = () => {
    return requestRefrences(setRefrences);
  };

  const updateRefrencesTimerFromServer = () => {
    return requestRefrencesTimeout(setRefrencesTimer, setWorkingRefrencesTimer);
  };

  // Define an effect to move to the next chapter
  const handleStartChapter = async () => {
    if (stopMessageBeforeChapter) {
      setOpenDialog(true);
    } else {
      const response = await requestQuestion();
      console.log("function response: ", response);

      if (
        !(
          response === "Chapter Ended" ||
          response === "Test Ended" ||
          response === "Questionnaire Ended"
        )
      ) {
        history.push("/questionnaire");
        window.location.reload();
      }
    }
  };

  const handleDialogClose = async continueChapter => {
    setOpenDialog(false);
    if (continueChapter) {
      const response = await requestQuestion();
      console.log("function response: ", response);

      if (
        !(
          response === "Chapter Ended" ||
          response === "Test Ended" ||
          response === "Questionnaire Ended"
        )
      ) {
        history.push("/questionnaire");
        window.location.reload();
      }
    }
  };

  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  return (
    <>
      {TopNav(true, true)}
      {HeadLine()}
      <Card sx={{ maxWidth: 3000, margin: "auto", mt: 4 }}>
        <CardContent>
          {refrencesTimer > 0 && (
            <Typography variant="h6" sx={{ mb: 2 }}>
              {formatTime(refrencesTimer)} זמן עד תחילת הפרק
            </Typography>
          )}
          <Typography
            variant="h6"
            sx={{ mb: 2 }}
            style={{
              textAlign: "center",
              fontFamily: "Arial"
            }}
          >
            לפניכם חומרי עזר, עיינו בהם היטב וכשתסיימו עברו לשלב השאלות
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || refrencesTimer > 0}
            onClick={() => {
              setLoading(true);
              handleStartChapter();
              setLoading(false);
            }}
            sx={{ display: "block", margin: "auto" }}
          >
            תחילת הפרק
          </Button>
          {refrences && RefrencesRender(refrences)}
        </CardContent>
      </Card>
      <StopDialog
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        stopMessageBeforeChapter={stopMessageBeforeChapter}
      ></StopDialog>
    </>
  );
};

export default OnlyRefrencesRender;
