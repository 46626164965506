import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { ClientWebSocketService } from "../../../../services/ClientWebSocketService";
import history from "../../../../services/history";
import { operational } from "../../../../services/api";

const host = operational ? "amnon.westeurope.cloudapp.azure.com" : "localhost";

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh"
});

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px"
});

const TextFieldStyled = styled(TextField)({
  width: "300px"
});

const ButtonStyled = styled(Button)({
  width: "200px"
});

const Register = ({ setWebSocketConnectionFunction }) => {
  const [username, setUsername] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      // Send registration data to the server
      const response = await fetch(`https://${host}/api/register/register`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ username })
      }).catch(error => {
        console.log(error);
        setOpen(true);
        setMessage(".ההתחברות נכשלה. נסה שוב");
      });

      // Assuming registration is successful, redirect the user to the questionnaire page
      response.json().then(data => {
        // Log the server response
        // userLogger.log(data);

        const cookie = data.cookie;
        console.log("user cookie: ", cookie);
        if (data.message === "Registration successful") {
          // Set the cookie value in localStorage
          localStorage.setItem("cookie", cookie);
          // Set the username value in localStorage
          localStorage.setItem("username", username);
          const websocketPort = data.websocketPort;
          localStorage.setItem("webSocketPort", websocketPort);

          const websocket = new ClientWebSocketService(
            username,
            cookie,
            websocketPort
          );
          setWebSocketConnectionFunction(websocket);

          const questionnaireState = data.questionnaire_state;
          const testEnded = questionnaireState.testEnded;
          const questionnaireEnded = questionnaireState.questionnaireEnded;
          const chapterEnded = questionnaireState.chapterEnded;
          const isStartChapter = questionnaireState.startOfChapter;
          const chapterHasRefrencePage =
            questionnaireState.chapterHasRefrencePage;
          const stopAnsweringChapter = questionnaireState.stopAnsweringChapter;

          // Redirect to the correct page
          if (testEnded && stopAnsweringChapter) {
            history.push("/questionnaire/end-test");
            window.location.reload();
          } else if (questionnaireEnded && stopAnsweringChapter) {
            history.push("/questionnaire/end-questionneer");
            window.location.reload();
          } else if (chapterEnded && stopAnsweringChapter) {
            history.push("/questionnaire/end-chapter");
            window.location.reload();
          } else if (isStartChapter && chapterHasRefrencePage) {
            history.push("/questionnaire/start-chapter");
            window.location.reload();
          } else {
            history.push("/questionnaire");
            window.location.reload();
          }

          // Set the webSocketPort value in localStorage

          // navigate("/questionnaire/start-chapter");
        } else {
          // pop up a message to the user that the registration failed
          setOpen(true);
          setMessage(".ההתחברות נכשלה. נסה שוב");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormContainer>
      <h1>הרשמה</h1>
      <Form onSubmit={handleSubmit}>
        <TextFieldStyled
          label="תעודת זהות"
          variant="outlined"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <ButtonStyled type="submit" variant="contained" color="primary">
          Register
        </ButtonStyled>
      </Form>
      {open && (
        <Alert severity="error" open={open} onClose={() => setOpen(false)}>
          {message}
        </Alert>
      )}
    </FormContainer>
  );
};

export default Register;
