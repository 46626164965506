import axios from "axios";

const operational = true;
const host = operational ? "amnon.westeurope.cloudapp.azure.com" : "localhost";

const api = axios.create({
  baseURL: `https://${host}/api`, // Replace with your server's URL
  withCredentials: true // Include cookies in requests
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 400) {
      // Call your function here
      console.log("Status 400 error occurred");
    }
    return Promise.reject(error);
  }
);


export default api;
export { operational };