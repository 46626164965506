import { Button } from "@mui/material";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useState } from "react";
import {
  postMoveToChapterRefrences,
  postMoveToPreviousChapter,
  postMoveToPreviousTest,
  postReturnFromToilet
} from "../../Operator/OperatorAPI/OperatorPost";

const UserReturnDialog = ({ onClose, user }) => {
  const [skipType, setReturnType] = useState("");
  const handleReturnTest = userId => {
    postMoveToPreviousTest(userId);
  };

  const handleReturnChapter = userId => {
    postMoveToPreviousChapter(userId);
  };

  const handleReturnToRefrences = userId => {
    postMoveToChapterRefrences(userId);
  };

  const handleReturnFromToilet = userId => {
    postReturnFromToilet(userId);
  };

  const handleReturnTypeSelect = (type, user) => {
    setReturnType(type);
    if (type === "test") {
      handleReturnTest(user.id);
    } else if (type === "chapter") {
      handleReturnChapter(user.id);
    } else if (type === "refrences") {
      handleReturnToRefrences(user.id);
    } else if (type === "toilet") {
      handleReturnFromToilet(user.id);
    }
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{`Return ${user?.name}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to Return {user?.name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleReturnTypeSelect("toilet", user)}
          color="primary"
        >
          Return From Toilet
        </Button>
        <Button
          onClick={() => handleReturnTypeSelect("refrences", user)}
          color="primary"
        >
          Return To Refrences
        </Button>
        <Button
          onClick={() => handleReturnTypeSelect("test", user)}
          color="primary"
        >
          Return Test
        </Button>
        <Button
          onClick={() => handleReturnTypeSelect("chapter", user)}
          color="primary"
        >
          Return Chapter
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UserReturnDialog;
