import { default as React, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  ClientWebSocketService,
  WebSocketContext
} from "../services/ClientWebSocketService";
import history from "../services/history";
import AdminPage from "./pages/Admin/AdminPage";
import Questionnaire from "./pages/ClientSideQuestionnaire";
import Home from "./pages/Home";
import OperatorPage from "./pages/Operator/OperatorPage";
import SimpleRegister from "./pages/PageUtils/General/SimpleRegister";
import PauseScreen from "./pages/PauseScreen";
import { requestQuestionnaireStatus } from "./pages/Questionnaire/QuestionneerAPI/QuestionneerGet";
import ClientChapterEnding from "./pages/Questionnaire/QuestionnerRenders/ChapterEndingRender";
import OnlyRefrencesRender from "./pages/Questionnaire/QuestionnerRenders/OnlyRefrencesRender";
import ClientQuestionnaireEnding from "./pages/Questionnaire/QuestionnerRenders/QuestionnaireEndingRender";
import ClientTestEnding from "./pages/Questionnaire/QuestionnerRenders/TestEndingRender";
import HTMLViewer from "./pages/Questionnaire/RefrencesFetcher/ResourcesRendering/HTMLViewer";
import PDFViewer from "./pages/Questionnaire/RefrencesFetcher/ResourcesRendering/ProtectedPdf";

const App = () => {
  const [testEnded, setTestEnded] = useState(false);
  const [questionnaireEnded, setQuestionnaireEnded] = useState(false);
  const [chapterEnded, setChapterEnded] = useState(false);
  const [isStartChapter, setIsStartChapter] = useState(true);
  const [stopAnsweringChapter, setStopAnsweringChapter] = useState(false); // [TODO
  const [webSocket, setWebSocket] = useState(null);
  const [webSocketBinded, setWebSocketBinded] = useState(false);
  const broadcastChannel = new BroadcastChannel("toiletChannel");

  const goToToilet = event => {
    const eventData = JSON.parse(event.data);
    const message = eventData.message;
    if (message === "Go To Toilet") {
      broadcastChannel.postMessage({ message: "Go To Toilet" });

      history.push("/pause-screen");
      window.location.reload();
    }
  };

  useEffect(() => {
    const handleBroadcastMessage = event => {
      const message = event.data.message;
      if (message === "Go To Toilet") {
        history.push("/pause-screen");
        window.location.reload();
      } else if (message === "Back From Toilet") {
        history.back();
      }
    };

    broadcastChannel.addEventListener("message", handleBroadcastMessage);

    return () => {
      broadcastChannel.removeEventListener("message", handleBroadcastMessage);
    };
  }, []);

  useEffect(() => {
    if (webSocket && !webSocketBinded) {
      webSocket.ws.addEventListener("message", goToToilet);
      // console.log("bind toilet message");
      // webSocket.bindMessage("Go To Toilet", goToToilet);
      setWebSocketBinded(true);
    }
  }, [webSocket, webSocketBinded]);

  useEffect(() => {
    // Fetch the initial status and update the state variables

    const fetchStatus = async () => {
      try {
        const response = await requestQuestionnaireStatus();
        setTestEnded(response.testEnded);
        setQuestionnaireEnded(response.questionnaireEnded);
        setChapterEnded(response.chapterEnded);
        setIsStartChapter(response.startOfChapter);
        setStopAnsweringChapter(response.stopAnsweringChapter);
      } catch (error) {
        console.log("error", error);
        setIsStartChapter(true);
      }
    };

    fetchStatus();
  }, []);

  useEffect(() => {
    // Get the WebSocket connection from localStorage
    const storedWebSocket = JSON.parse(localStorage.getItem("webSocket"));

    if (storedWebSocket) {
      // Set the WebSocket connection in state
      setWebSocket(storedWebSocket);
    }
  }, []);

  useEffect(() => {
    // Get the WebSocket connection from localStorage
    const webSocketPort = localStorage.getItem("webSocketPort");

    // Create a new WebSocket connection if the webSocket state is null
    if (webSocket === null && webSocketPort) {
      const username = localStorage.getItem("username");
      const cookie = localStorage.getItem("cookie");
      if (!webSocketPort || !username || !cookie) {
        return;
      }

      const websocket = new ClientWebSocketService(
        username,
        cookie,
        webSocketPort
      );

      setWebSocket(websocket);
    }

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      if (webSocket !== null) {
        webSocket.disconnect();
      }
      // localStorage.removeItem("webSocket");
    };
  }, [webSocket]);

  const setWebSocketConnection = ws => {
    setWebSocket(ws);
  };

  return (
    <WebSocketContext.Provider value={webSocket}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Home setWebSocketConnectionFunction={setWebSocketConnection} />
            }
          />
          <Route
            path="/admin"
            element={<SimpleRegister registerType="admin" />}
          />
          <Route path="/admin/manager" element={<AdminPage />} />
          <Route path="/questionnaire/pdf-file" element={<PDFViewer />} />
          <Route path="/questionnaire/html-file" element={<HTMLViewer />} />

          <Route
            path="/operator"
            element={<SimpleRegister registerType="operator" />}
          />
          <Route path="/operator/manager" element={<OperatorPage />} />
          <Route path="/pause-screen" element={<PauseScreen />} />
          <Route path="/questionnaire" element={<Questionnaire />} />
          <Route
            path="/questionnaire/end-test"
            element={
              stopAnsweringChapter && testEnded ? (
                <ClientTestEnding />
              ) : (
                <h1>המבחן עדיין לא נגמר</h1>
              )
            }
          />
          <Route
            path="/questionnaire/end-questionneer"
            element={
              stopAnsweringChapter && questionnaireEnded ? (
                <ClientQuestionnaireEnding />
              ) : (
                <h1>המבחן עדיין לא נגמר</h1>
              )
            }
          />
          <Route
            path="/questionnaire/end-chapter"
            element={
              stopAnsweringChapter && chapterEnded ? (
                <ClientChapterEnding />
              ) : (
                <h1>הפרק עדיין לא נגמר</h1>
              )
            }
          />
          <Route
            path="/questionnaire/start-chapter"
            element={
              isStartChapter ||
                (!chapterEnded && !questionnaireEnded && !testEnded) ? (
                <OnlyRefrencesRender />
              ) : (
                <h1>לא תחילת הפרק</h1>
              )
            }
          />
        </Routes>
      </Router>
    </WebSocketContext.Provider>
  );
};

export default App;
