import { Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import userLogger from "../../services/userLogger";
import {
  postQuestionIndex,
  postQuestionnaireResponse
} from "./Questionnaire/QuestionneerAPI/QuestionneerPost";

import { WebSocketContext } from "../../services/ClientWebSocketService";
import history from "../../services/history";
import {
  addNewQuestion,
  addUserResponses,
  handleNextQuestion,
  handlePreviousQuestion,
  handleQuestionResponse
} from "./HandleQuestionsUtils";
import HeadLine from "./PageUtils/User/TestHeadLine";
import TopNav from "./PageUtils/User/TopNav/TopNav";
import WebcamRender from "./PageUtils/User/Webcam";
import {
  requestAllQuestions,
  requestChapterName,
  requestQuestionIndex,
  requestRefrences,
  requestShouldEndTest,
  requestTestName,
  requestChapterTimeout
} from "./Questionnaire/QuestionneerAPI/QuestionneerGet";
import RenderedQuestion from "./Questionnaire/QuestionnerRenders/QuestionRender";
import RefrencesRender from "./Questionnaire/QuestionnerRenders/RefrencesRender";

const Questionnaire = () => {
  // Define state variables for the component
  const [questions, setQuestions] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [firstRun, setFirstRun] = useState(true);
  const [userResponses, setUserResponses] = useState([]);
  const [shouldSubmitQuestionnaire, setShouldSubmitQuestionnaire] =
    useState(false);
  const [startOfChapterIndex, setStartOfChapterIndex] = useState(0);
  const [gotAllQuestions, setGotAllQuestions] = useState(false);
  const [gotFirstIndex, setGotFirstIndex] = useState(false);
  const [refrences, setRefrences] = useState([]);
  const [shouldEndTest, setShouldEndTest] = useState(false);
  const [gotShouldEndTest, setGotShouldEndTest] = useState(false);
  const [binded, setBinded] = useState(false);
  const [testName, setTestName] = useState("");
  const [chapterName, setChapterName] = useState("");
  const webSocket = useContext(WebSocketContext);
  const [chapterTimer, setChapterTimer] = useState(0);
  const [workingChapterTimer, setWorkingChapterTimer] = useState(false);
  const [presentTimer, setPresentTimer] = useState(false);

  useEffect(() => {
    if (workingChapterTimer) {
      const interval = setInterval(() => {
        if (chapterTimer % 30 === 0) updateChapterTimerFromServer();
        else {
          setChapterTimer(chapterTimer => chapterTimer - 1);
        }
      }, 1000);

      if (chapterTimer <= 0) {
        clearInterval(interval);
      }

      return () => clearInterval(interval);
    }
  }, [workingChapterTimer, chapterTimer]);

  const updateChapterTimerFromServer = () => {
    return requestChapterTimeout(setChapterTimer, setWorkingChapterTimer);
  };

  const updateNamesFromServer = () => {
    requestTestName(setTestName);
    requestChapterName(setChapterName);
  };

  const goToRefrences = eventData => {
    history.push("/questionnaire/start-chapter");
    window.location.reload();
  };

  const updateQuestionIndex = eventData => {
    setQuestionIndex(eventData.data);
  };

  const updateStartOfChapterIndex = eventData => {
    setStartOfChapterIndex(eventData.data);
  };
  // Bind the websocket message
  useEffect(() => {
    if (webSocket && !binded) {
      webSocket.bindMessage("Go To Refrences", goToRefrences);
      webSocket.bindMessage("QuestionIndex", updateQuestionIndex);
      webSocket.bindMessage("ChapterStartIndex", updateStartOfChapterIndex);
      setBinded(true);
    }
  }, [webSocket, binded]);

  // Define an effect to request all questions from the server on the first run of the component
  useEffect(() => {
    if (firstRun) {
      updateQuestionIndexFromServer();
      updateAllQuestionsFromServer();
      updateRefrencesFromServer();
      updateShouldEndTest();
      updateResponsesFromServer();
      updateNamesFromServer();
      updateChapterTimerFromServer();
      presentChapterTimer();
      if (gotAllQuestions && gotFirstIndex) {
        if (questions.length == questionIndex) {
          updateQuestionFromServer();
        }
        // updateAllQuestionsFromServer();
        setFirstRun(false);
      }
    }
  }, [firstRun, userResponses, questionIndex, questions]);

  useEffect(() => {
    if (!gotFirstIndex) {
      updateQuestionIndexFromServer();
    }
  }, [gotFirstIndex]);

  useEffect(() => {
    if (!testName || !chapterName) {
      updateNamesFromServer();
      presentChapterTimer()
    }
  }, [testName, chapterName]);

  useEffect(() => {
    if (!userResponses || (userResponses.length == 0 && questionIndex > 0)) {
      updateResponsesFromServer();
      console.log("updating responses from server");
    }
  }, [userResponses]);

  useEffect(() => {
    if (questions && questions.length === 0) {
      updateAllQuestionsFromServer();
      updateQuestionIndexFromServer();
    }
  }, [questionIndex, questions]);

  useEffect(() => {
    if (
      (questions &&
        userResponses &&
        questions.length - 1 > userResponses.length) ||
      (questions && !userResponses)
    ) {
      console.log("updating responses from server");
      updateResponsesFromServer();
    }
  }, [questions, userResponses]);

  // Use useEffect to ensure that userResponses is updated before sending the POST request
  useEffect(() => {
    if (shouldSubmitQuestionnaire) {
      sendQuestionnaireResponse();
      setShouldSubmitQuestionnaire(false);
    }
  }, [shouldSubmitQuestionnaire, userResponses]);

  // Define the function handles the refrencesTimer for the client website
  useEffect(() => {
    if (gotShouldEndTest) {
      const interval = setInterval(() => {
        updateShouldEndTest();
      }, 5000);
      if (shouldEndTest) {
        clearInterval(interval);
        // postMoveToNextChapter();
        history.push("/questionnaire/end-test");
        window.location.reload();
      }
      return () => clearInterval(interval);
    }
  }, [gotShouldEndTest, shouldEndTest]);

  // send question index to server
  const sendQuestionIndex = async questionIndex => {
    console.log("sendQuestionIndex", questionIndex);
    if (questions && questionIndex >= 0 && questionIndex < questions.length) {
      setQuestionIndex(questionIndex);
      console.log("running postQuestionIndex");

      await postQuestionIndex(questionIndex);
    } else {
      userLogger.log("index out of range");
    }
  };

  // send questionnaire response to server
  const sendQuestionnaireResponse = async () => {
    await postQuestionnaireResponse(userResponses, testName, chapterName);
  };

  const updateQuestionIndexFromServer = async () => {
    return await requestQuestionIndex(setQuestionIndex, setGotFirstIndex);
  };

  // all the questions from server are recived with index, order them by it
  const setQuestionsOrdered = questions => {
    const orderedQuestions = questions.sort((a, b) => {
      return a.questionIndex - b.questionIndex;
    });
    setQuestions(orderedQuestions);
  };

  // update all questions from server
  const updateAllQuestionsFromServer = () => {
    return requestAllQuestions(setQuestionsOrdered, setGotAllQuestions);
  };

  // update responses from server
  const updateResponsesFromServer = async () => {
    return await addUserResponses(
      userResponses,
      questionIndex,
      setUserResponses
    );
  };

  const updateQuestionFromServer = async () => {
    return await addNewQuestion(
      questions.length > 0 ? questions[questionIndex] : null,
      setQuestions,
      questions
    );
  };

  const updateRefrencesFromServer = () => {
    return requestRefrences(setRefrences);
  };

  const updateShouldEndTest = () => {
    return requestShouldEndTest(setShouldEndTest, setGotShouldEndTest);
  };

  // Define a function to handle the "Previous" button click
  const previousQuestion = async () => {
    return await handlePreviousQuestion(
      questionIndex,
      userResponses,
      testName,
      chapterName,
      questions,
      setUserResponses,
      setQuestionIndex
    );
  };

  // Define a function to handle the "Next" button click
  const nextQuestion = async (shouldJumpPages = true) => {
    return await handleNextQuestion(
      gotFirstIndex,
      userResponses,
      questionIndex,
      questions,
      setGotFirstIndex,
      setQuestionIndex,
      setQuestions,
      testName,
      chapterName,
      setUserResponses,
      shouldJumpPages
    );
  };

  const questionResponse = async response => {
    return await handleQuestionResponse(
      response,
      questionIndex,
      userResponses,
      setUserResponses,
      updateResponsesFromServer
    );
  };

  // Get the current question
  const currentQuestion = questions[questionIndex];
  // Get the selected answer index for the current answer (the index of the answer that the user selected with checkbox)
  let selectedAnswerIndex;
  if (
    userResponses &&
    userResponses.length > 0 &&
    userResponses[questionIndex] &&
    userResponses[questionIndex].response
  ) {
    selectedAnswerIndex = userResponses[questionIndex].response.response;
  } else {
    selectedAnswerIndex = null;
  }

  // For the event of the user finishing the questionnaire or finishing the chapter we want to render a different component
  let finalRenderedQuestionComponent = null;
  finalRenderedQuestionComponent = RenderedQuestion(
    currentQuestion,
    questionIndex,
    selectedAnswerIndex,
    startOfChapterIndex,
    questionResponse,
    previousQuestion,
    nextQuestion,
    chapterName,
  );
  const chapterLastQuestionIndex = questions.length - 1;
  const chapterFirstQuestionIndex = 0;


  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const presentChapterTimer = () => {
    if (testName.includes("יפנית") || testName.includes("מוח") || testName.includes("הנחות") || testName.includes("אישיותי")) {
      setPresentTimer(true)
    }
  }
  return (
    <div>
      {TopNav(
        true,
        true,
        chapterLastQuestionIndex + 1,
        chapterFirstQuestionIndex,
        sendQuestionIndex
      )}
      {chapterTimer >= 0 && presentTimer && (
        <Typography variant="h5" sx={{ mb: 0 }} align="left">
          {formatTime(chapterTimer)} זמן עד סוף הפרק
        </Typography>
      )}
      {WebcamRender()}
      {HeadLine(testName)}
      {/* Render the final questionnaire component */}
      {finalRenderedQuestionComponent}
      {/* Render the references component */}
      {refrences && RefrencesRender(refrences)}
    </div>
  );
};

export default Questionnaire;
