import { Button } from "@mui/material";
import { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import {
  postChangeChapterTime,
  postChangeReferencesTime,
  postChangeTestTime
} from "../../Operator/OperatorAPI/OperatorPost";

const UserTimingDialog = ({ onClose, user }) => {
  const [minutesSkip, setMinutesSkip] = useState(0);

  const handleChangeTestTime = (userId, shouldIncrease) => {
    postChangeTestTime(userId, minutesSkip, shouldIncrease);
    console.log(`Skipping test for user with id ${userId}`);
    onClose();
  };

  const handleChangeChapterTime = (userId, shouldIncrease) => {
    postChangeChapterTime(userId, minutesSkip, shouldIncrease);
    onClose();
  };
  const handleChangeReferencesTime = (userId, shouldIncrease) => {
    postChangeReferencesTime(userId, minutesSkip, shouldIncrease);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}
      maxWidth="sm"  // Adjust size: 'xs', 'sm', 'md', 'lg', 'xl'
      fullWidth={true} >
      <DialogTitle>{`Change timing for ${user?.name}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want change the test/chapter time for: {user?.name}?
        </DialogContentText>
        <Box marginTop={3}>
          <TextField
            label="Minutes"
            type="number"
            value={minutesSkip}
            onChange={event => setMinutesSkip(event.target.value)}
            inputProps={{ min: 0 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleChangeTestTime(user.id, true)}
          color="primary"
        >
          Extend Test
        </Button>
        <Button
          onClick={() => handleChangeChapterTime(user.id, true)}
          color="primary"
        >
          Extend Chapter
        </Button>
        <Button
          onClick={() => handleChangeTestTime(user.id, false)}
          color="primary"
        >
          Reduce Test
        </Button>
        <Button
          onClick={() => handleChangeChapterTime(user.id, false)}
          color="primary"
        >
          Reduce Chapter
        </Button>
        <Button
          onClick={() => {
            handleChangeReferencesTime(user.id, false);
          }}
          color="primary"
        >
          Reduce References
        </Button>
        <Button
          onClick={() => {
            handleChangeReferencesTime(user.id, true);
          }}
          color="primary"
        >
          Extend References
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UserTimingDialog;
