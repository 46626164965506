import { Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { requestTestName } from "../../Questionnaire/QuestionneerAPI/QuestionneerGet";

const HeadLine = (text = null) => {
  const [testNameFromServer, setTestNameFromServer] = useState("");

  useEffect(() => {
    if (!text) {
      requestTestName(setTestNameFromServer);
    }
  }, [text]);

  const nameToDisplay = text || testNameFromServer || "";
  return (
    <Typography
      variant="h3"
      component="h1"
      gutterBottom
      style={{
        textAlign: "center",
        fontFamily: "Arial",
        textDecoration: "underline"
      }}
      dir="rtl"
    >
      {nameToDisplay}
    </Typography>
  );
};

export default HeadLine;
