/**
 * this class will be used to display the ending of the test
 * @fileoverview ClientTestEnding Component
 *
 */
import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import history from "../../../../services/history";
import HeadLine from "../../PageUtils/User/TestHeadLine";
import TopNav from "../../PageUtils/User/TopNav/TopNav";
import { requestQuestionIndex } from "../QuestionneerAPI/QuestionneerGet";
import { postEndQuestionnaire } from "../QuestionneerAPI/QuestionneerPost";
import { Container, StyledButton } from "../styles";

const { Title } = Typography;

const ClientQuestionnaireEnding = () => {
  const [firstRun, setFirstRun] = useState(true);
  const [gotFirstIndex, setGotFirstIndex] = useState(false);
  const [questionIndex, setQuestionIndex] = useState([]);

  const updateQuestionIndexFromServer = () => {
    return requestQuestionIndex(setQuestionIndex, setGotFirstIndex);
  };

  useEffect(() => {
    if (firstRun) {
      updateQuestionIndexFromServer();
      if (gotFirstIndex) {
        setFirstRun(false);
      }
    }
  }, [firstRun]);

  // Define a function that handle questionnaire submit
  const handleQuestionnaireSubmit = () => {
    postEndQuestionnaire();
  };

  const handleEndTestClick = () => {
    handleQuestionnaireSubmit();
    history.push("/");
    window.location.reload();
  };

  const previousChapter = () => {
    history.push("/questionnaire");
    window.location.reload();
  };

  const handlePreviousChapterClick = () => {
    previousChapter();
  };
  return (
    <>
      {TopNav()}
      {HeadLine()}

      <Container>
        <Title level={3}>!כל המבחנים נגמרו</Title>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleEndTestClick}
        >
          !להתראות
        </StyledButton>
        {/* <StyledButton
          variant="contained"
          disabled={gotFirstIndex && questionIndex == 0}
          onClick={handlePreviousChapterClick}
        >
          עיון בשאלות
        </StyledButton> */}
      </Container>
    </>
  );
};

export default ClientQuestionnaireEnding;
