import { FormControl, FormControlLabel, Radio } from "@mui/material";
import React from "react";

const MultiChoiceQuestion = ({
  id,
  question_text,
  options,
  selectedOptionId,
  onResponse
}) => {
  const handleOptionChange = event => {
    const selectedResponse = event.target.value;
    onResponse({ questionID: id, response: selectedResponse });
  };

  const question_html =
    '<h3 style={{ marginBottom: "1rem", textAlign: "right" }}><div dir="rtl">' +
    question_text.replace(/\n/g, "<br>").replace("\\n", "<br>") +
    "</div></h3>";
  return (
    <div style={{ textAlign: "right" }}>
      <div dangerouslySetInnerHTML={{ __html: question_html }} />
      <FormControl component="fieldset">
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            control={<Radio color="primary" />}
            labelPlacement="start"
            label={
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div dangerouslySetInnerHTML={{ __html: option.text }} />
              </div>
            }
            value={option.id}
            checked={option.id == selectedOptionId}
            onChange={handleOptionChange}
            style={{ marginBottom: "0.5rem" }}
          />
        ))}
      </FormControl>
    </div>
  );
};
export default MultiChoiceQuestion;
