import { Container } from "@mui/material";
import React from "react";
import Register from "./PageUtils/General/Register";
import TopNav from "./PageUtils/User/TopNav/TopNav";

const Home = ({ setWebSocketConnectionFunction }) => {
  return (
    <>
      {TopNav()}
      <Container maxWidth="sm">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginTop: "-50px" // Add this CSS rule to move the Register component up
          }}
        >
          <Register
            setWebSocketConnectionFunction={setWebSocketConnectionFunction}
          />
        </div>
        {/* Other content */}
      </Container>
    </>
  );
};

export default Home;
