import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import UpdateQuestionnaireTests from "../PageUtils/Admin/UpdateQuestionnaireTests";
import UpdateUsers from "../PageUtils/Admin/UpdateUsers";
import TopNav from "../PageUtils/User/TopNav/TopNav";
import {
  downloadQuestionnaireTests,
  downloadUsersResults,
  downloadUsersAttended
} from "./AdminAPI/AdminGet";

import {
  postLoadUsersFromResults,
  postTestUsersQuestions,
  postUpdateTests,
  postUpdateUsers,
  postDeleteResponses,
  postDeleteAttendedUsers
} from "./AdminAPI/AdminPost";

const Title = styled(Typography)`
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
`;

const AdminPage = () => {
  // function for submit users csv file to server
  const sendUsersFunction = usersJson => {
    postUpdateUsers(usersJson);
  };

  // function to get users results
  const getUsersResults = () => {
    let results = [];
    const setResults = response => {
      results = response;
    };
    downloadUsersResults();
  };


  // function to get users results
  const getAttendedUsers = () => {
    downloadUsersAttended();
  };

  // function to load users from results
  const loadUsersFromResults = () => {
    postLoadUsersFromResults();
  };

  const loadUsersQuestions = () => {
    postTestUsersQuestions();
  };

  const deleteResponses = () => {
    postDeleteResponses();
  };

  const deleteAttendedUsers = () => {
    postDeleteAttendedUsers();
  };

  return (
    <>
      {TopNav()}
      <Title variant="h4">Admin Page</Title>
      {UpdateUsers(sendUsersFunction)}
      <div style={{ marginTop: "16px" }}>
        <label>Get Users Results: </label>
        <button variant="contained" onClick={getUsersResults}>
          Get Users Results
        </button>
      </div>
      <div style={{ marginTop: "8px" }}>
        <label>Delete Responses: </label>
        <button variant="contained" onClick={deleteResponses}>
          Delete Responses
        </button>
      </div>
      <div style={{ marginTop: "32px" }}>
        <label>Download Questionnaire Tests: </label>
        <button variant="contained" onClick={downloadQuestionnaireTests}>
          Download Questionnaire Tests
        </button>
      </div>
      <div style={{ marginTop: "8px" }}>
        {UpdateQuestionnaireTests(postUpdateTests)}
      </div>
      <div style={{ marginTop: "8px" }}>
        <button variant="contained" onClick={getAttendedUsers}>
          get attended users
        </button>
      </div>
      <div style={{ marginTop: "8px" }}>
        <button variant="contained" onClick={deleteAttendedUsers}>
          delete attended users
        </button>
      </div>
      <div style={{ marginTop: "8px" }}>
        <button variant="contained" onClick={loadUsersFromResults}>
          Test Load Users From Results
        </button>
      </div>
      <div style={{ marginTop: "8px" }}>
        <button variant="contained" onClick={loadUsersQuestions}>
          Test send questions from users
        </button>
      </div>

    </>
  );
};

export default AdminPage;
