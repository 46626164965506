import api from "./api";
const DEBUG = false;

const userLogger = {
  debug: function (message) {
    this.log(message, "debug");
  },
  info: function (message) {
    this.log(message, "info");
  },
  error: function (message, alreadyLogged = false, sendAnyway = false) {
    if (alreadyLogged && !sendAnyway) {
      return;
    }
    this.log(message, "error", DEBUG, sendAnyway);
    console.error(message);
  },
  log: function (message, level = "info", should_console = true, sendAnyway = false) {
    const logMessage = `[${new Date().toISOString()}] [${level}] ${message}`;
    if (should_console && DEBUG) {
      console.log(logMessage);
    }
    const data = { message: logMessage, level: level };
    if (DEBUG || sendAnyway) {
      api
        .post("/log/logging", data, { withCredentials: true })
        .then(response => {
          if (!response.data === "OK") {
            console.error(`Error writing to log file: ${response.status}`);
          }
        })
        .catch(error => {
          this.error("Error updating index:" + error, true);
          // Handle the error if needed
        });
    }
  }
};

export default userLogger;
