import { ProtectedReference } from "./ProtectedResource";
import { operational } from "../../../../../services/api";

const host = operational ? "amnon.westeurope.cloudapp.azure.com" : "localhost";

const HTMLViewer = ({ resourcePath = null }) => {
  // pass params as a prop
  const urlParams = new URLSearchParams(window.location.search);
  let path = resourcePath;
  if (!resourcePath) {
    path = urlParams.get("path");
  }
  // get all options otehr than path
  let otherOptions = {};
  for (let [key, value] of urlParams) {
    if (key !== "path") {
      otherOptions[key] = value;
    }
  }
  const encodedResourcePath = encodeURIComponent(path);

  const otherOptionsPath = otherOptions
    ? `&${new URLSearchParams(otherOptions).toString()}`
    : "";

  console.log("is operational:", operational);
  console.log("my host: ", host);
  const htmlPath = `https://${host}/api/questionnaire/get-html-file?html=${encodedResourcePath}${otherOptionsPath}`;

  return (
    <div>
      <ProtectedReference
        refrenceRender={
          <div>
            <iframe
              style={{ width: "100%", height: "100vh", border: "none" }}
              src={htmlPath} // use the modified srcDoc
            />
          </div>
        }
      />
    </div>
  );
};

export default HTMLViewer;
