import { Button } from "@mui/material";
import { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import { postSendMessageToSomeone } from "../../Operator/OperatorAPI/OperatorPost";
const UserMessageDialog = ({ onClose, user }) => {
  const [message, setMessage] = useState("");

  const sendMessage = () => {
    // Send message to server
    if (user) {
      postSendMessageToSomeone(user.id, message);
    }
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{`Return ${user?.name}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to Return {user?.name}?
        </DialogContentText>
      </DialogContent>
      <div style={{ marginRight: "150px" }}>
        <TextField
          margin="dense"
          type="text"
          label={`שלח הודעה ל${user?.name}`}
          value={message}
          fullWidth
          dir="rtl"
          onChange={event => setMessage(event.target.value)}
        />
        <Button color="primary" onClick={sendMessage}>
          שלח הודעה ל{user?.name}
        </Button>
      </div>
    </Dialog>
  );
};
export default UserMessageDialog;
