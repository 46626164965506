import api from "../../../../services/api";
import history from "../../../../services/history";
import userLogger from "../../../../services/userLogger";

// Reusable function to handle unauthorized access
const handleUnauthorizedAccess = response => {
  if (
    response.status === 400 ||
    (response.data &&
      response.data.message &&
      response.data.message === "User authentication failed")
  ) {
    // Perform the redirect or other actions    console.log("bad post request");
    history.push("/");
    window.location.reload();
  }
  // Handle other errors as needed

  return response;
};

// Define a function to send the user's responses to the server
const postQuestionnaireResponse = async (responses, testName, chapterName) => {
  // Send the data to the server using axios
  api
    .post(
      "/questionnaire/questionnaire",
      { responses: responses, chapterName: chapterName, testName: testName },
      { withCredentials: true }
    )
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      userLogger.log("Server response:", response.data);
      // Handle the response from the server if needed
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error sending questionnaire response:", error);
      // Handle the error if needed
    });
};

// Update start of chapter index in server
const postStartOfChapterIndex = new_index => {
  const data = { chapterStartIndex: new_index };
  api
    .post("/questionnaire/update-chapter-start-index", data)
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      userLogger.log("Start of chapter index updated");
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error updating start of chapter index", error);
    });
};

// Define an effect to move to next chapter
const postMoveToNextChapter = async chapterName => {
  await api
    .post("/questionnaire/next-chapter", { chapterName: chapterName })
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (response.data.message == "User has moved to next chapter") {
        userLogger.log("User has moved to next chapter");
      } else if (response.data.message == "User has finished all chapters") {
        userLogger.log("User has finished questionnaire");
        history.push("/questionnaire/end-questionneer");
        window.location.reload();
      }
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error updating postMoveToNextChapter", error);
    });
};

// Define an effect to move to next test
const postMoveToNextTest = async testName => {
  await api
    .post("/questionnaire/next-test", { testName: testName })
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      if (response.data.message == "User has moved to next test") {
        userLogger.log("User has moved to next test");
      } else if (response.data.message == "User has finished questionnaire") {
        userLogger.log("User has finished questionnaire");
        history.push("/questionnaire/end-questionneer");
        window.location.reload();
      }
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error updating next test", error);
    });
};

// Define a function to update the question index on the server
const postQuestionIndex = async new_index => {
  const data = { questionIndex: new_index };
  userLogger.debug(`Updating question index to ${new_index}`);
  api
    .post("/questionnaire/update-question-index", data, {
      withCredentials: true
    })
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      userLogger.debug(`Question index updated successfully to ${new_index}`);
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error updating index:", error);
      // Handle the error if needed
    });
};

const postToiletbrakeRequest = () => {
  api
    .post("/questionnaire/toilet-beake-request")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      userLogger.log("Toilet request sent");
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error sending toilet request", error);
    });
};

const postAlarmtbrakeRequest = () => {
  postToiletbrakeRequest();
};

const postRaiseHandRequest = (message, queueID = null) => {
  const data = { message, queueID };

  api
    .post("/questionnaire/raise-hand-question-request", data)
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      userLogger.log("Raise hand request sent with message:", message);
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error sending raise hand request", error);
    });
};

const postRaisedHandQuestionClosed = queueID => {
  const data = { queueID };
  api
    .post("/questionnaire/raised-hand-question-closed", data)
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      userLogger.log("Raised hand question closed");
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error sending raised hand question closed", error);
    });
};

const postBackFromToiletBrake = () => {
  api
    .post("/questionnaire/back-from-toilet-brake")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      userLogger.log("Back from toilet brake");
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error sending back from toilet brake", error);
    });
};

const postEndQuestionnaire = () => {
  api
    .post("/questionnaire/end-questionnaire")
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      userLogger.log("Questionnaire ended");
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error sending end questionnaire", error);
    });
};

const postFinishedChapter = async chapterName => {
  await api
    .post("/questionnaire/finished-chapter", { chapterName: chapterName })
    .then(response => handleUnauthorizedAccess(response))
    .then(response => {
      userLogger.log("Finished chapter");
    })
    .catch(error => {
      history.push("/");
      window.location.reload();
      console.error("Error sending finished chapter", error);
    });
};

export {
  postAlarmtbrakeRequest,
  postBackFromToiletBrake,
  postEndQuestionnaire,
  postFinishedChapter,
  postMoveToNextChapter,
  postMoveToNextTest,
  postQuestionIndex,
  postQuestionnaireResponse,
  postRaiseHandRequest,
  postRaisedHandQuestionClosed,
  postStartOfChapterIndex,
  postToiletbrakeRequest
};
