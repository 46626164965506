/**
 * Class that contains the rendered question
 */
import { Typography } from "@mui/material";
import React, { useState } from "react";
import MultiChoiceQuestion from "../MultiChoiseQuestion";
import { postFinishedChapter } from "../QuestionneerAPI/QuestionneerPost";
import OpenQuestion from "../openQuestion";
import { ButtonContainer, Container, StyledButton } from "../styles";
import StopDialog from "./StopDialog";
import { operational } from "../../../../services/api";
import zIndex from "@mui/material/styles/zIndex";

const host = operational ? "amnon.westeurope.cloudapp.azure.com" : "localhost";

const RenderedQuestion = (
  currentQuestion,
  currentQuestionIndex,
  selectedAnswerIndex,
  startOfChapterIndex,
  handleQuestionResponse,
  handlePreviousQuestion,
  handleNextQuestion,
  chapterName
) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  // this function will reformat the question text, if it contains iframe transform the url to be correct
  const questionTextReformat = questionText => {
    let newText = questionText;
    if (questionText.includes("<iframe")) {
      newText = questionText
        .replace("?mode", "&mode")
        .replace("?text", "&text")
        .replace(
          `src="/Refrences`,
          `src=https://${host}/api/questionnaire/get-html-file?html=./Refrences`
        )
        .replace(
          `src="./Refrences`,
          `src=https://${host}/api/questionnaire/get-html-file?html=./Refrences`
        );
    }
    return newText;
  };

  const handleDialogClose = async shouldJumpPages => {
    setLoading(true);
    setOpenDialog(false);
    if (shouldJumpPages) {
      await postFinishedChapter(chapterName);
      await handleNextQuestion(true);
    }
    setLoading(false);
  };

  const handleNextClick = async () => {
    setLoading(true);
    const response = await handleNextQuestion(false);
    if (
      response === "Chapter Ended" ||
      response === "Test Ended" ||
      response === "Questionnaire Ended"
    ) {
      setOpenDialog(true);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const handlePreviousClick = async () => {
    setLoading(true);
    await handlePreviousQuestion();
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  return (
    <>
      <Container style={{ zIndex: 500 }} sx={{ maxWidth: "800px" }}>
        <Typography variant="h5" component="h2" gutterBottom>
          שאלה {currentQuestionIndex + 1 - startOfChapterIndex}
        </Typography>
        {currentQuestion &&
          currentQuestion["options"] &&
          (currentQuestion["options"].length > 1 ||
            (currentQuestion["options"].length == 1 &&
              currentQuestion["options"][0].text === "אני מתחייב")) && (
            <MultiChoiceQuestion
              id={currentQuestion["id"]}
              question_text={questionTextReformat(
                currentQuestion["question_text"]
              )}
              options={currentQuestion["options"].map(option => ({
                id: option.id,
                text: questionTextReformat(option.text)
              }))}
              selectedOptionId={selectedAnswerIndex}
              onResponse={handleQuestionResponse}
            />
          )}
        {currentQuestion &&
          currentQuestion["options"] &&
          currentQuestion["options"].length <= 1 &&
          !(
            currentQuestion["options"].length == 1 &&
            currentQuestion["options"][0].text === "אני מתחייב"
          ) && (
            <OpenQuestion
              id={currentQuestion["id"]}
              question_text={currentQuestion["question_text"]}
              onResponse={handleQuestionResponse}
              type={currentQuestion["options"][0] ? currentQuestion["options"][0].text : null}
              selectedResponse={selectedAnswerIndex}
            />
          )}
        <ButtonContainer>
          <StyledButton
            variant="contained"
            disabled={loading}
            onClick={handleNextClick}
          >
            הבא
          </StyledButton>
          <StyledButton
            variant="contained"
            disabled={
              loading ||
              currentQuestionIndex === startOfChapterIndex ||
              currentQuestionIndex === 0
            }
            onClick={handlePreviousClick}
          >
            קודם
          </StyledButton>
        </ButtonContainer>
      </Container >
      <StopDialog
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        stopMessageBeforeChapter={
          "הפרק נגמר, במעבר לפרק הבא לא ניתן לשנות את התשובות של הפרק"
        }
      ></StopDialog>
    </>
  );
};
export default RenderedQuestion;
