import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { WebSocketContext } from "../../../../../../services/ClientWebSocketService";
import { requestRaisedHandQuestions } from "../../../../Questionnaire/QuestionneerAPI/QuestionneerGet";
import {
  postRaiseHandRequest,
  postRaisedHandQuestionClosed
} from "../../../../Questionnaire/QuestionneerAPI/QuestionneerPost";

const RaiseHand = () => {
  const [first, setFirst] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [answeredCount, setAnsweredCount] = useState(0);
  const [questionsQueues, setQuestionsQueues] = useState([]);
  const [followUpQuestions, setFollowUpQuestions] = useState({});
  const webSocket = useContext(WebSocketContext);
  const [showText, setShowText] = useState(false);

  const gotNewQuestion = data => {
    updateQuestionsQueues(data);
  };

  const updateQuestionsQueues = data => {
    setQuestionsQueues(data.data);
  };

  const handleMouseEnter = () => {
    setShowText(true);
  };

  const handleMouseLeave = () => {
    setShowText(false);
  };

  if (first && webSocket) {
    requestRaisedHandQuestions(setQuestionsQueues);
    webSocket.bindMessage("Update Request Raised Hand", gotNewQuestion);
    setFirst(false);
  }

  useEffect(() => {
    if (questionsQueues && questionsQueues.length > 0) {
      setAnsweredCount(questionsQueues.filter(q => !q.answered).length);
    }
  }, [questionsQueues]);

  const handleFollowUpQuestionClick = queueId => {
    postRaiseHandRequest(followUpQuestions[queueId], queueId);
    setFollowUpQuestions({ ...followUpQuestions, [queueId]: "" });
    if (questionsQueues && questionsQueues.length > 0) {
      const queue = questionsQueues.find(queue => queue.id === queueId);
      queue.isAllAnswered = false;
      queue.isOpen = true;
    }
  };

  const handleRaiseHand = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (questionsQueues && questionsQueues.length > 0) {
      questionsQueues.map(queue => {
        if (queue.isAllAnswered) {
          postRaisedHandQuestionClosed(queue.id);
          queue.isOpen = false;
        }
      });
    }
  };

  const handleNewRaisedHandQuestion = () => {
    postRaiseHandRequest(message);
    setOpen(false);
  };

  const handleMessageChange = event => {
    setMessage(event.target.value);
  };

  const queueDisplay = (queue, i) => {
    return (
      <div>
        <div key={i} style={{ marginTop: "50px", marginBottom: "20px" }}>
          {!queue.operatorMessage &&
            queue.questions &&
            queue.questions.map((question, index) => (
              <div key={index}>
                <p dir="rtl">תקלה: {question.message}</p>
                {question.answers.map((answer, index) => (
                  <p key={index} dir="rtl">
                    תשובה: {answer}
                  </p>
                ))}
              </div>
            ))}
          {queue.operatorMessage &&
            queue.questions &&
            queue.questions.map((question, index) => (
              <div key={index}>
                <p dir="rtl">הודעה מהמפעיל: {question.message}</p>
              </div>
            ))}
        </div>
        {!queue.operatorMessage && (
          <div>
            <TextField
              margin="dense"
              id={`follow-up-question-${queue.id}`}
              label="שאלת המשך"
              type="text"
              fullWidth
              value={followUpQuestions[queue.id] || ""}
              onChange={event =>
                setFollowUpQuestions({
                  ...followUpQuestions,
                  [queue.id]: event.target.value
                })
              }
              dir="rtl"
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleFollowUpQuestionClick(queue.id)}
              >
                שלח שאלת המשך
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <button
          onClick={handleRaiseHand}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="RaiseHand-button"
          style={{
            fontSize: "2em",
            backgroundColor:
              questionsQueues &&
                questionsQueues.some(queue => queue.isOpen && queue.isAllAnswered) >
                0
                ? "yellow"
                : "#333"
          }}
        >
          🛠️
        </button>
        {showText && (
          <div
            style={{
              position: 'absolute',
              zIndex: 9999,
              top: '30px', // Adjust this value to move the text box relative to the button
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '3px',
              backgroundColor: '#fff',
              border: '1px solid #333',
              borderRadius: '4px',
              color: '#000',
              fontSize: '0.8em',
            }}
          >
            דיווח על תקלה - רעננו בשביל תשובה
          </div>
        )}
      </div >
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle dir="rtl">{"דיווח על תקלה:"}</DialogTitle>

        <TextField
          autoFocus
          margin="dense"
          id="message"
          label="תקלה"
          type="text"
          fullWidth
          onChange={handleMessageChange}
          dir="rtl"
          align="right"
        />

        <DialogActions>
          <Button
            sx={{ display: "block", margin: "auto" }}
            onClick={handleClose}
            variant="contained"
            color="primary"
          >
            ביטול
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ display: "block", margin: "auto" }}
            onClick={handleNewRaisedHandQuestion}
          >
            שלח
          </Button>
        </DialogActions>
        {questionsQueues &&
          questionsQueues.length > 0 &&
          questionsQueues.some(queue => queue.operatorMessage) && (
            <div>
              <h3 dir="rtl">הודעות מהמפעיל:</h3>
              <ul>
                {questionsQueues.map((queue, i) => (
                  <div key={i}>
                    {queue.operatorMessage && queueDisplay(queue, i)}
                  </div>
                ))}
              </ul>
            </div>
          )}
        {questionsQueues &&
          questionsQueues.length > 0 &&
          questionsQueues.some(
            queue =>
              queue.isOpen && queue.isAllAnswered && !queue.operatorMessage
          ) && (
            <div>
              <h3 dir="rtl">תקלות חדשות שנענו:</h3>
              <ul>
                {questionsQueues.map((queue, i) => (
                  <div key={i}>
                    {queue.isOpen &&
                      queue.isAllAnswered &&
                      !queue.operatorMessage &&
                      queueDisplay(queue, i)}
                  </div>
                ))}
              </ul>
            </div>
          )}
        {questionsQueues &&
          questionsQueues.length > 0 &&
          questionsQueues.some(
            queue =>
              !queue.operatorMessage && (!queue.isOpen || !queue.isAllAnswered)
          ) && (
            <div>
              <h3 dir="rtl">תקלות:</h3>
              <ul>
                {questionsQueues &&
                  questionsQueues.map((queue, i) => (
                    <div key={i}>
                      {!queue.operatorMessage &&
                        (!queue.isOpen || !queue.isAllAnswered) &&
                        queueDisplay(queue, i)}
                    </div>
                  ))}
              </ul>
            </div>
          )}
      </Dialog>
    </>
  );
};

export default RaiseHand;
