// create a dialog that ask you a question before letting you proceed to the next chapter
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import React, { useState } from "react";

const StopDialog = ({
  openDialog,
  handleDialogClose,
  stopMessageBeforeChapter
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
      <DialogTitle style={{ direction: "rtl", textAlign: "center" }}>
        שים לב!
      </DialogTitle>
      <DialogContentText
        style={{ direction: "rtl", textAlign: "right" }}
        sx={{ padding: "0 16px" }}
      >
        {stopMessageBeforeChapter}
      </DialogContentText>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            setLoading(true);
            handleDialogClose(true);
            setLoading(false);
          }}
          autoFocus
        >
          המשך
        </Button>
        <Button onClick={() => handleDialogClose(false)}>ביטול</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StopDialog;
