import Webcam from "react-webcam"

import React, { useState } from 'react';
import "../../../Styles/Webcam.css";

const WebcamRender = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [recordingMessage, setrecordingMessage] = useState('');
    return (
        <div className="webcam-container">
            <Webcam
                className="webcam-real"
                width="12%"
                mirrored
                onUserMedia={() => setrecordingMessage("🔴 REC")}
                onError={(error) => {
                    setErrorMessage('There was an error accessing your camera.');
                }}
                onUserMediaError={(error) => {
                    if (error.message === 'Permission denied') {
                        setErrorMessage('!יש לתת הרשאות למצלמה');
                    }
                    else {
                        setErrorMessage('!יש לחבר מצלמה');
                    }
                }}
            />
            {errorMessage && (
                <p className="error-message">{errorMessage}</p>
            )}
            {recordingMessage && (
                <p className="recording-message">{recordingMessage}</p>
            )}
        </div>

    );
};

export default WebcamRender